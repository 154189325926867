export default {
  "en": {
      'CONTENT': 'CONTENT',
      'TO THE REAL': 'TO THE REAL',
      'DEMANDS OF SKIN':" DEMANDS OF SKIN",
      'ENJOYMENT':"ENJOYMENT",
      'MEDICAL GRADE':"MEDICAL GRADE",
      'PROFESSIONAL':" PROFESSIONAL",
      'NURSING':" NURSING",
      'ACCURATE ':"ACCURATE ",
      'SPECTRUM':" SPECTRUM",
      'REVITALIZE SKIN':" REVITALIZE SKIN",
      'VE AESTHETIC':" VE AESTHETIC",
      'PROGRAM':" PROGRAM",
      'AESTHETIC INSIGHT':"AESTHETIC INSIGHT",
      'Test the 6 major skin health indicators':" Test the 6 major skin health indicators",
      'Give you the confidence and direction to treat.':"Give you the confidence and direction to treat.",
      'REALLY NEEDS':"REALLY NEEDS",
      'One-on-One Guest Consultant':"One-on-One Guest Consultant",
      'Precise understanding of your beauty needs.':"Precise understanding of your beauty needs.",
      'ONE-STOP SOLUTION':"ONE-STOP SOLUTION",
      'Full range of medical aesthetic programs':"Full range of medical aesthetic programs",
      'Multi-dimensional solutions from the surface to the inside.':"Multi-dimensional solutions from the surface to the inside.",
      'LIFE AND SPACE':"LIFE AND SPACE",
      'A healing and quiet salon treatment room':"A healing and quiet salon treatment room",
      'Enjoy the original power of beauty in peaceful rest.':"Enjoy the original power of beauty in peaceful rest.",
      'NATURE TO BEAUTY':"NATURE TO BEAUTY",
      'In a more efficient and natural way':"In a more efficient and natural way",
      'Let life and aesthetics balance and symbiosis.':"Let life and aesthetics balance and symbiosis.",
      'PRODUCT ALLIANCE':"PRODUCT ALLIANCE",
      'LOCATE US':"LOCATE US",
      '241 River Valley Road':"241 River Valley Road",
      'Gianurn Building #03-01':"Gianurn Building #03-01",
      'Singapore 238298':"Singapore 238298",
      'HAVE A QUESTION？':" HAVE A QUESTION？",
      'Speak to our VE therapeutics':"Speak to our VE therapeutics",
      'experts today！':" experts today！",
      'EMAIL':"EMAIL",
      'PHONE':"PHONE",
      'NAME':"NAME",
      'I HAVE A QUESTION ABOUT... ':"I HAVE A QUESTION ABOUT... ",
      'TELL US MORE!':"TELL US MORE! ",
      'SUBMIT':"SUBMIT",
      'Select About':"Select About",
      'VIEW MORE':"VIEW MORE",
      'SELECT':"SELECT",
      'VE BRAND':"VE BRAND",
      'POWER INSTRUMENT':"POWER INSTRUMENT",
      'MEDICAL INJECTIONS':"MEDICAL INJECTIONS",
      'V-CURVE MAINTAIN':"V-CURVE MAINTAIN",
      'PRECISE SKIN CARE':"PRECISE SKIN CARE",
      'EXPLORE':"EXPLORE",
      'SPECIALTY':"SPECIALTY",
      'JOIN US':"JOIN US",
      'VE CLUB':"VE CLUB",
      'NEW CONCEPT':"NEW CONCEPT",
      'VE Medical Aesthetics, as the creator of the "light" medical aesthetic industry, will bring you a':" VE Medical Aesthetics, as the creator of the 'light 'medical aesthetic industry, will bring you a",
      'With "LOVE&ENJOY" as our brand concept, we are committed to bring you a more quality life,':"With 'LOVE&ENJOY' as our brand concept, we are committed to bring you a more quality life,",
      'confidence and courage.':"confidence and courage.",
      'warm and highly non-surgical rejuvenation experience.':"warm and highly non-surgical rejuvenation experience.",
      'Create a more dynamic medical aesthetic industry.':"Create a more dynamic medical aesthetic industry.",
      '"FOUR STEPS"':'"FOUR STEPS"',
      'STEP':'STEP',
      'THE MEDICAL CONCEPT':'THE MEDICAL CONCEPT',
      'OF HUMAN NATURE':' OF HUMAN NATURE',
      'advanced technology to make beauty safer and more efficient.':'advanced technology to make beauty safer and more efficient.',
      'Combining traditional medical beauty with':'Combining traditional medical beauty with',
      '"DOUBLE BEAUTY" SYMBIOSIS':'"DOUBLE BEAUTY" SYMBIOSIS',
      'A NEW CONCEPT OF':'A NEW CONCEPT OF',
      'Experience non-invasive medical aesthetics, bringingmedical beauty closer to life.':'Experience non-invasive medical aesthetics, bringingmedical beauty closer to life.',
      'CREATE A BETTER SOLUTION':'CREATE A BETTER SOLUTION',
      'FOR MEDICAL BEAUTY':'FOR MEDICAL BEAUTY',
      "Recognized by the world's leading high-end":"Recognized by the world's leading high-end",
      'brands, one-stop solution to your skin problems.':'brands, one-stop solution to your skin problems.',
      'Combining traditional medical beauty with':'Combining traditional medical beauty with',
      'PERSONAL CUSTOMIZATION':'  PERSONAL CUSTOMIZATION',
      'AND SMART SERVICE':'AND SMART SERVICE  ',
      'the First Time to Every Time.':'the First Time to Every Time.',
      'Create a Customized Anti-Aging Strategy From':'Create a Customized Anti-Aging Strategy From   ',
      'BRAND':'  BRAND  ',
      'SPECIALITY':'SPECIALITY  ',
      'HIGHLIGHT OF TIME':'HIGHLIGHT OF TIME',
      "Strictly select the world's leading FDA-certified photoelectric beauty equipment.":" Strictly select the world's leading FDA-certified photoelectric beauty equipment.",
      'Experience higher-end non-surgical "light" medical beauty.':'Experience higher-end non-surgical "light" medical beauty.  ',
      'FULL OF VERVE':'FULL OF VERVE  ',
      'careFeel the instant beauty transformation.':'careFeel the instant beauty transformation.',
      "From medical grade injections to daily skin":" From medical grade injections to daily skin",
      'AESTHETIC GUIDANCE':'AESTHETIC GUIDANCE  ',
      'Let your unique temperament and beauty':'Let your unique temperament and beauty',
      "coexistLet the change follow the trend of the times.":"coexistLet the change follow the trend of the times.",
      'INSTANT VISIBILITY ':'INSTANT VISIBILITY   ',
      'In their own pace of lifeSolve most skin':'In their own pace of lifeSolve most skin',
      "problems":"problems",
      'BRAND ':'BRAND  ',
      'TRACEABILITY':'TRACEABILITY',
      'positioned the concept of "light medical beauty"to create a new generation of medical beauty brand.':'positioned the concept of "light medical beauty"to create a new generation of medical beauty brand.  ',
      'The origin of the brand, the principal of VE':'The origin of the brand, the principal of VE',
      'To obtain a license to practice medicine for ':'To obtain a license to practice medicine for ',
      'safer and more effective medical aesthetics.':'safer and more effective medical aesthetics.',
      "Authorized by dibi brand, authorized by SKEYNDOR brand.":"Authorized by dibi brand, authorized by SKEYNDOR brand.",
      "Received medical accreditation,located in the heart of Singapore, and implemented the construction of the first flagship store.":" Received medical accreditation,located in the heart of Singapore, and implemented the construction of the first flagship store.",
      "":"",
      'grade equipment, expert endorsement team formation completed.':'grade equipment, expert endorsement team formation completed.',
      'Introduction of fda-approved medical-':'Introduction of fda-approved medical-',
      'Recognized by leading-edge optoelectronic':'Recognized by leading-edge optoelectronic',
      'equipment brands in the United States, Israel, and multiple countries in China.':'equipment brands in the United States, Israel, and multiple countries in China.',
      "IN THE MEDICAL BEAUTY INDUSTRY":"IN THE MEDICAL BEAUTY INDUSTRY ",
      '"BLACK TECHNOLOGY"':'"BLACK TECHNOLOGY"',
      'Professional light medical beauty service':'Professional light medical beauty service',
      "team, top beauty academy training ended.":"team, top beauty academy training ended.",
      "Focusing on non-surgical rejuvenation, Ve's":"Focusing on non-surgical rejuvenation, Ve's",
      'emerging light medical aesthetic institution officially opened.':'emerging light medical aesthetic institution officially opened.',
      'FDA&NMPA dual certification,Unique Focus diffractive lens,Precise shooting of melanin particles.':'FDA&NMPA dual certification,Unique Focus diffractive lens,Precise shooting of melanin particles.',
      "EQUIPMENT":"EQUIPMENT",
      "The equipment is FDA approved,thus":"The equipment is FDA approved,thus",
      "ensuring that your choice is safe andeffective.":"ensuring that your choice is safe andeffective.",
      "beauty industry, radiating to more than 100 countries around the world and integrating the world's latest technology.":"beauty industry, radiating to more than 100 countries around the world and integrating the world's latest technology.",
      "We select the world's biggest names in the":"We select the world's biggest names in the",
      "PRODUCTS":"PRODUCTS",
      "DOCTORS":"DOCTORS",
      "Preferred team of doctors, all qualified topractice medicine in Singapore, to escort your beautyjourney safely.":"Preferred team of doctors, all qualified topractice medicine in Singapore, to escort your beautyjourney safely.",
      "VE STANDARD":"VE STANDARD",
      "VE adheres to the original intention of providing sincere service to customers, making each treatment safe and effective,and reassuring every customer.":"VE adheres to the original intention of providing sincere service to customers, making each treatment safe and effective,and reassuring every customer.",
      "From the front desk, consultants, doctors, nurses to post-operative services, we create the ultimate experience process from smile greeting, professional consultation & treatment & service, personalized service & personal responsibility, tracking service, private private butler service, transparent price system, etc.":"From the front desk, consultants, doctors, nurses to post-operative services, we create the ultimate experience process from smile greeting, professional consultation & treatment & service, personalized service & personal responsibility, tracking service, private private butler service, transparent price system, etc.",
      " ALLIANCE ":" ALLIANCE ",
      " PRODUCT ":" PRODUCT ",
      "VE brand is committed to bringing quality,safety and comfort to the new generation of light luxury beauty services, combining technology and natural ingredients, integrating black technology and cutting-edge beauty equipment.":"VE brand is committed to bringing quality,safety and comfort to the new generation of light luxury beauty services, combining technology and natural ingredients, integrating black technology and cutting-edge beauty equipment.",
      "Points can be accumulated based on consumption, and accumulated points can be redeemed for daily items.":"Points can be accumulated based on consumption, and accumulated points can be redeemed for daily items.",
      "With the world's":"With the world's ",
      'leading brands to give customers a "lighter" more "efficient" and "healthier" skin experience.':'leading brands to give customers a "lighter" more "efficient" and "healthier" skin experience.',
      "POINTS REDEMPTION":"POINTS REDEMPTION",
      "VALUE ADDED":"VALUE ADDED",
      "SERVICES":"SERVICES ",
      "Have a beauty personal consultant and exclusive member discounts.":"Have a beauty personal consultant and exclusive member discounts.",
      "OFF-LINE ACTIVITIES":" OFF-LINE ACTIVITIES ",
      "PREMIUM EXPERIENCE":"PREMIUM EXPERIENCE",
      "Branded afternoon tea events, beauty mini-classes and other offline activities.":"Branded afternoon tea events, beauty mini-classes and other offline activities.", 
      "Birthday gift, new project experience, vip VIP room, free drink afternoon tea.":      "Birthday gift, new project experience, vip VIP room, free drink afternoon tea.",
      "PRECISE":"PRECISE ",
      "SKIN CARE":"SKIN CARE ",
      "V-CURVE":"V-CURVE ",
      "MAINTAIN":"MAINTAIN ",
      "MEDICAL":"MEDICAL ",
      "INJECTIONS":"INJECTIONS ",
      "POWER":"POWER ",
      "PICOSURE":"PICOSURE",
      "INSTRUMENT":"INSTRUMENT ",
      'FOCUS ON "HONEYCOMB LENS"':'FOCUS ON "HONEYCOMB LENS"',
      'A picosecond equals one trillionth of a second,Faster speed instantly crushes pigment technology,shorten treatment time.':'A picosecond equals one trillionth of a second,Faster speed instantly crushes pigment technology,shorten treatment time.',
      'INSTANTANEOUS "PIGMENT BLAST"':'INSTANTANEOUS "PIGMENT BLAST"',
      'Unique 755nm wavelength, While reducing heatdamage to the skin, Deter melanin activity, Reduce therisk of returning to black.':'Unique 755nm wavelength, While reducing heatdamage to the skin, Deter melanin activity, Reduce therisk of returning to black.',
      'ENJOY "CLEAR, GLOWING SKIN"':'ENJOY "CLEAR, GLOWING SKIN"',
      'Initiate cell release of protein, let the skin producenew collagen and elastin from within, Gently smoothes the skin.':'Initiate cell release of protein, let the skin producenew collagen and elastin from within, Gently smoothes the skin.',
      'PROMOTE "PLANTAR NEOGENESIS"':'PROMOTE "PLANTAR NEOGENESIS"',
      'FREQUENTLY ASKED QUESTIONS':'FREQUENTLY ASKED QUESTIONS',
      'RECOMMENDED PRODUCTS':'RECOMMENDED PRODUCTS',
      "Honeycomb picosecond uses a laser and a honeycomb lens together to aggregate most of the laser energy and shatter melanin in different skin layers using targeted treatment. Honeycomb picosecond can":"Honeycomb picosecond uses a laser and a honeycomb lens together to aggregate most of the laser energy and shatter melanin in different skin layers using targeted treatment. Honeycomb picosecond can",
      "promote the regeneration of collagen and collagen fibers, speed up the body's metabolism, and solve the deep skin melanin problem from the root, to achieve the purpose of whitening and rejuvenation.":"promote the regeneration of collagen and collagen fibers, speed up the body's metabolism, and solve the deep skin melanin problem from the root, to achieve the purpose of whitening and rejuvenation. ",
      "No. Hive picosecond can penetrate into the deeper layers of the skin, and only the melanin particles are affected, so there is no damage to the surface skin.":'No. Hive picosecond can penetrate into the deeper layers of the skin, and only the melanin particles are affected, so there is no damage to the surface skin.',
      "All laser treatments are not guaranteed to be completely free of re-blackening, but the risk of re- blackening is much lower than that of traditional lasers because of the small damage to the skin":"All laser treatments are not guaranteed to be completely free of re-blackening, but the risk of re- blackening is much lower than that of traditional lasers because of the small damage to the skin",
      "and the return of redness and swelling to the camp. However, after surgery, you need to strengthen sun protection measures and do a good job of hydration. If the post-operative care is not proper, there is also a certain degree of risk of re-tanning.":"and the return of redness and swelling to the camp. However, after surgery, you need to strengthen sun protection measures and do a good job of hydration. If the post-operative care is not proper, there is also a certain degree of risk of re-tanning.",
      "Honeycomb picosecond can fade scars, for deep scar depressions it needs to be combined with fractional laser in conjunction with the treatment.":"Honeycomb picosecond can fade scars, for deep scar depressions it needs to be combined with fractional laser in conjunction with the  treatment.",
      "There are many factors that affect the results of Honeycomb picosecond surgery, mainly the mode, number of times, post-operative care and so on. Generally, the results can be seen within 7 days after Honeycomb picosecond surgery because the skin needs a repair cycle.":"There are many factors that affect the results of Honeycomb picosecond surgery, mainly the mode, number of times, post-operative care and so on. Generally, the results can be seen within 7 days after Honeycomb picosecond surgery because the skin needs a repair cycle.",
      "LIFT SKIN, CHANGE AGE":"LIFT SKIN, CHANGE AGE",
      'Dual "Focus Technology"':'Dual "Focus Technology"',
      'Convenient and safe treatment with immediate "lunchtime" results.':'Convenient and safe treatment with immediate "lunchtime" results.',
      'Highly effective "non-invasive lift"':'Highly effective "non-invasive lift"',
      'From NASA "Infrared Skin Beauty"':'From NASA "Infrared Skin Beauty"',
      '360 degree "Painless"':'360 degree "Painless"',
      'Top "Stable Energy"':'Top "Stable Energy"',
      'Three main functions':'Three main functions',
      'Deep cleansing:':'Deep cleansing:',
      'cleanse epidermis of sebum, dirt and excess keratin.':'cleanse epidermis of sebum, dirt and excess keratin.',
      'Regulate keratin:':'Regulate keratin:',
      'increase the thickness of the epidermis and repair the skin barrier.':'increase the thickness of the epidermis and repair the skin barrier.',
      "Dual micro and macro focus, fast and precise sensor for sensitive areas.":"Dual micro and macro focus, fast and precise sensor for sensitive areas.",
      "Multi-depth probes quickly address the different depths and levels of skin aging.":"Multi-depth probes quickly address the different depths and levels of skin aging.",
      "Rejuvenation":"Rejuvenation",
      "The 7d Poly Latti can show the tightening and lifting effect quickly after the treatment, but the effect is not permanent, generally can maintain 2~3 years, the maintenance time is related to the doctor's operation level, the patient's skin condition and post-operative care. The interval between 7d Poly Lattis should not be less than two years.":"The 7d Poly Latti can show the tightening and lifting effect quickly after the treatment, but the effect is not permanent, generally can maintain 2~3 years, the maintenance time is related to the doctor's operation level, the patient's skin condition and post-operative care. The interval between 7d Poly Lattis should not be less than two years.",
      "It is normal to experience dryness after surgery, as long as the cosmetic device is implemented through thermal excitation, it will bring about a little damage to the moisture layer and evaporate a large amount of water under the skin at the same time.":"It is normal to experience dryness after surgery, as long as the cosmetic device is implemented through thermal excitation, it will bring about a little damage to the moisture layer and evaporate a large amount of water under the skin at the same time.",
      "The 7d Poly Latti is performed by focusing ultrasonic energy on the dermis and SMAS fascia layer of the face, heating the tissue to 65°C~75°C. Therefore, it is better to say that it is painful rather than hot; most people do not feel the pain of it, and if the pain of the previous machine lift is ten points, the 7d Poly Latti is less than five points.":"The 7d Poly Latti is performed by focusing ultrasonic energy on the dermis and SMAS fascia layer of the face, heating the tissue to 65°C~75°C. Therefore, it is better to say that it is painful rather than hot; most people do not feel the pain of it, and if the pain of the previous machine lift is ten points, the 7d Poly Latti is less than five points.",
      "The 7d Poly Latti procedure takes about 40 minutes to complete and you will notice a tightening of the skin after the procedure, which will disappear about 2-3 days after the procedure.":"The 7d Poly Latti procedure takes about 40 minutes to complete and you will notice a tightening of the skin after the procedure, which will disappear about 2-3 days after the procedure.",
      "1. After the 7d Poly Latti, you must do a good job of moisturizing and nursing, it is recommended to put on a mask for three days, and try not to wear makeup.":"1. After the 7d Poly Latti, you must do a good job of moisturizing and nursing, it is recommended to put on a mask for three days, and try not to wear makeup.",
      "2. After the treatment, you should avoid going in and out of high heat environment, such as sauna, sauna, etc., so as not to affect the treatment effect. ":"2. After the treatment, you should avoid going in and out of high heat environment, such as sauna, sauna, etc., so as not to affect the treatment effect. ",
      "3. After the treatment, there may be slight redness and small tingling, which are normal and mild temporary reactions, no need to worry. ":"3. After the treatment, there may be slight redness and small tingling, which are normal and mild temporary reactions, no need to worry. ",
      "4. according to medical advice twice a day ACME-TEA activation protein, diet to avoid spicy mouth, tobacco and alcohol can not account for, eat more fruits and vegetables, eat lighter food.":"4. according to medical advice twice a day ACME-TEA activation protein, diet to avoid spicy mouth, tobacco and alcohol can not account for, eat more fruits and vegetables, eat lighter food.",
      "NASA MILK LIGHT":"NASA MILK LIGHT",
      "THE SKIN LIKE MILK":"THE SKIN LIKE MILK",
      "Selected 900-1800mm near infrared spectrum, directly to the dermis, for cellular empowerment.":"Selected 900-1800mm near infrared spectrum, directly to the dermis, for cellular empowerment.",
      "Even energy coverage, in-motion glide technology like hands, touching the whole body.":"Even energy coverage, in-motion glide technology like hands, touching the whole body.",
      "Heraeus top infrared light source, precise and constant energy, instantly visible through white.":"Heraeus top infrared light source, precise and constant energy, instantly visible through white.",
      "Usually a single NIR Milk Light treatment takes only about 20 minutes and the results will be seen in one treatment. However, anti-aging programs for skin aging are not permanent. Combating ongoing aging requires continuous treatment to repair the skin and requires a regular course of therapeutic treatments.":"Usually a single NIR Milk Light treatment takes only about 20 minutes and the results will be seen in one treatment. However, anti-aging programs for skin aging are not permanent. Combating ongoing aging requires continuous treatment to repair the skin and requires a regular course of therapeutic treatments.",
      "Milk Light penetrates deep into the skin through infrared light waves, stimulating subcutaneous collagen growth, promoting fibroblast reorganization, smoothing wrinkles and shrinking pores, and restoring the skin's original elasticity. It can effectively solve skin problems such as redness and skin dullness.":"Milk Light penetrates deep into the skin through infrared light waves, stimulating subcutaneous collagen growth, promoting fibroblast reorganization, smoothing wrinkles and shrinking pores, and restoring the skin's original elasticity. It can effectively solve skin problems such as redness and skin dullness.",
      "Milk Light is a kind of photorejuvenation, which uses red light wavelength to act directly on the dermis layer of the skin, stimulating the cells in the skin and making the skin smooth and delicate with new growth. The frequency of the program is around 20-30 days, which needs to be decided according to individual skin repair ability.":" Milk Light is a kind of photorejuvenation, which uses red light wavelength to act directly on the dermis layer of the skin, stimulating the cells in the skin and making the skin smooth and delicate with new growth. The frequency of the program is around 20-30 days, which needs to be decided according to individual skin repair ability.",
      "-Wash your face with room temperature water on the day after the operation;":"-Wash your face with room temperature water on the day after the operation;",
      "-Pay attention to sun protection after the operation;":"-Pay attention to sun protection after the operation;",
      "-Pay attention to more hydration and moisturizing after the operation;":"-Pay attention to more hydration and moisturizing after the operation;",
      "-No makeup is recommended on the day of operation.":"-No makeup is recommended on the day of operation.",
      "-Patients with serious diseases do not operate (three high diabetes heart disease);":"-Patients with serious diseases do not operate (three high diabetes heart disease);",
      "-Skin allergy is not allowed to operate;":"-Skin allergy is not allowed to operate;",
      "-The skin has wounds or sunburn can not operate;":"-The skin has wounds or sunburn can not operate;",
      "-Customers who are allergic to light cannot operate;":"-Customers who are allergic to light cannot operate;",
      "-Pregnant women are not allowed to operate.":"-Pregnant women are not allowed to operate.",
      "HYDRAFACIAL":"HYDRAFACIAL",
      "LIGHTNING REJUVENATION":"LIGHTNING REJUVENATION",
      "Purification:":"Purification:",
      "Resurfacing:":"Resurfacing:",
      "Moisturizing:":"Moisturizing:",
      "Blue whirlpool probe, Activ4 essence helps to replace keratin and revitalize the whole skin.":"Blue whirlpool probe, Activ4 essence helps to replace keratin and revitalize the whole skin.",
      "Green negative pressure probe, Beta HD extracts work precisely on T-zone, deep pore cleansing.":"Green negative pressure probe, Beta HD extracts work precisely on T-zone, deep pore cleansing.",
      "Transparent introduction probe, infusing Antiox+ essence into skin, strengthening keratin and protecting skin barrier.":"Transparent introduction probe, infusing Antiox+ essence into skin, strengthening keratin and protecting skin barrier.",
      "Hydrafacial deep cleanses the skin by fully combining ultra-microscopic small bubbles and nutrient solution, acting on the skin surface to deep cleanse the face to remove blackheads and old fee keratin without pain and provide nutrients to the skin.":"Hydrafacial deep cleanses the skin by fully combining ultra-microscopic small bubbles and nutrient solution, acting on the skin surface to deep cleanse the face to remove blackheads and old fee keratin without pain and provide nutrients to the skin.",
      "Many doctors in clinical medicine recommend that patients with sensitive skin try Hydrafacial treatments to help soothe and repair the skin barrier.":"Many doctors in clinical medicine recommend that patients with sensitive skin try Hydrafacial treatments to help soothe and repair the skin barrier.",
      "The treatment is non-invasive and comfortable. In addition, you can apply makeup directly after the treatment and it will look better.":"The treatment is non-invasive and comfortable. In addition, you can apply makeup directly after the treatment and it will look better.",
      "The first treatment with Hydrafacial will give you a noticeable refinement of the skin and an even, brighter complexion that will last for many days. With 1-2 treatments per month, you can continue to maintain healthy skin.":"The first treatment with Hydrafacial will give you a noticeable refinement of the skin and an even, brighter complexion that will last for many days. With 1-2 treatments per month, you can continue to maintain healthy skin.",
      "Hydrafacial is able to absorb deep-seated skin impurities and also has a revitalizing and rejuvenating effect. Common daily problems such as dull skin, pseudo-wrinkles and rough skin can be completely improved with Hydrafacial.":"Hydrafacial is able to absorb deep-seated skin impurities and also has a revitalizing and rejuvenating effect. Common daily problems such as dull skin, pseudo-wrinkles and rough skin can be completely improved with Hydrafacial.",
      "VISIA 7":"VISIA 7",
      "SKIN IMAGING SPECIALIST":"SKIN IMAGING SPECIALIST",
      "Three steps":"Three steps",
      "See spots, wrinkles, texture, pores, UV blemishes, brown spots, red areas and violet matter all at once for a precise understanding of skin condition.":"See spots, wrinkles, texture, pores, UV blemishes, brown spots, red areas and violet matter all at once for a precise understanding of skin condition.",
      'Skin "8 indicators"':'Skin "8 indicators"',
      "Let you visualize every subtle change, digitally assess skin condition and age, and guide treatment direction.":"Let you visualize every subtle change, digitally assess skin condition and age, and guide treatment direction.",
      'Integrated "Exclusive Cloud Library"':'Integrated "Exclusive Cloud Library',
      "VISIA is a professional skin image analysis system that uses advanced multi-spectral imaging technology and patented RBX technology, but since the state of the skin is constantly changing, the test results are only for reference, and specific treatment recommendations are still doctor-based.":"VISIA is a professional skin image analysis system that uses advanced multi-spectral imaging technology and patented RBX technology, but since the state of the skin is constantly changing, the test results are only for reference, and specific treatment recommendations are still doctor-based.",
      "The higher the score, the better. However, since it is a machine count, the value may fluctuate from one photo to another on the same day. The value is only for reference, and the doctor needs to see the photo in person to judge.":"The higher the score, the better. However, since it is a machine count, the value may fluctuate from one photo to another on the same day. The value is only for reference, and the doctor needs to see the photo in person to judge.",
      'Natural "Safety Gel"':'Natural "Safety Gel"',
      "The first hyaluronic acid to be approved by the FDA in the U.S., followed by CE certification in Europe and CFDA certification in China.":"The first hyaluronic acid to be approved by the FDA in the U.S., followed by CE certification in Europe and CFDA certification in China.",
      'Patented "Exclusive Technology"':'Patented "Exclusive Technology"',
      "HYLACROSS technology, which is silky smooth to the touch after injection, high integration, strong shaping power, and long maintenance time.":"HYLACROSS technology, which is silky smooth to the touch after injection, high integration, strong shaping power, and long maintenance time.",
      'Gentle "microbial fermentation"':'Gentle "microbial fermentation"',
      "Microbial fermentation method is used, and contains a small amount of numbing agent, which is safe and reduces the pain of injection.":"Microbial fermentation method is used, and contains a small amount of numbing agent, which is safe and reduces the pain of injection.",
      "JUVEDERM":"JUVEDERM",
      "12 MONTHS LONG-LASTING BEAUTY":"12 MONTHS LONG-LASTING BEAUTY",
      "There are no side effects, but bruising or swelling may occur within a few days after the project depending on the individual's body type, and there may be tightness and swelling in the filler area, which is normal.":"There are no side effects, but bruising or swelling may occur within a few days after the project depending on the individual's body type, and there may be tightness and swelling in the filler area, which is normal.",
      "The small amount of lidocaine contained in the Juvederm filler reduces the pain of the injection process to a certain extent. Most of the patients who had Juvederm filler injections reported that the pain level was tolerable.":"The small amount of lidocaine contained in the Juvederm filler reduces the pain of the injection process to a certain extent. Most of the patients who had Juvederm filler injections reported that the pain level was tolerable.",
      "The results of a Juvederm filler treatment last approximately 12 to 18 months, depending on the area being treated, the filler dose, and individual skin conditions.":"The results of a Juvederm filler treatment last approximately 12 to 18 months, depending on the area being treated, the filler dose, and individual skin conditions.",
      'BOTOX':'BOTOX',
      '"STAR':'"STAR',
      "'S":"'S",
      'CAUTION"':'CAUTION"',
      'Lower dispersion of 0.44cm, more natural aesthetic effect, precise and controllable wrinkle removal effect.':'Lower dispersion of 0.44cm, more natural aesthetic effect, precise and controllable wrinkle removal effect.',
      '30 years of international clinical use, quality assurance, 0 rejection injection for more safety.':'30 years of international clinical use, quality assurance, 0 rejection injection for more safety.',
      'The only "SFDA certification"':'The only "SFDA certification"',
      "Comprehensive":"Comprehensive",
      '"Precision Wrinkle Reduction"':'"Precision Wrinkle Reduction"',
      '"Instantly visible" effect':'"Instantly visible" effect',
      'Fastest "10 minutes" completion, no recovery period, visible results in 3-7 days.':'Fastest "10 minutes" completion, no recovery period, visible results in 3-7 days.',
      "Juvederm fillers are easier to shape and mold than collagen, provide significant improvement in clumping and uneven shape, last 3 times longer than collagen, and are softer and more natural than traditional fillers.":"Juvederm fillers are easier to shape and mold than collagen, provide significant improvement in clumping and uneven shape, last 3 times longer than collagen, and are softer and more natural than traditional fillers.",
      'The probability of side effects of BOTOX is very small and short-lived. Slight drooping of the corners of the eyes or drooping of the eyebrows occurs in 1-5% of cases and usually disappears within 2 weeks. And a few patients may experience slight bruising.':'The probability of side effects of BOTOX is very small and short-lived. Slight drooping of the corners of the eyes or drooping of the eyebrows occurs in 1-5% of cases and usually disappears within 2 weeks. And a few patients may experience slight bruising.',
      "BOTOX can be widely used to eliminate: frown lines between the eyebrows, horizontal head lines, crow's feet or laugh lines at the corners of the eyes, for wrinkles between the nose and lips, periorbital lines, and chin depressions or folds, etc. It has good effects. It can also be used for face slimming treatment.":"BOTOX can be widely used to eliminate: frown lines between the eyebrows, horizontal head lines, crow's feet or laugh lines at the corners of the eyes, for wrinkles between the nose and lips, periorbital lines, and chin depressions or folds, etc. It has good effects. It can also be used for face slimming treatment.",
      "BOTOX will take effect in about 3-7 days after injection, but the exact time varies from person to person and is generally determined by the rate of local absorption. If the body's metabolism is fast and the local absorption rate is relatively fast, the effect may be seen in about 3 days.":"BOTOX will take effect in about 3-7 days after injection, but the exact time varies from person to person and is generally determined by the rate of local absorption. If the body's metabolism is fast and the local absorption rate is relatively fast, the effect may be seen in about 3 days.",
      'The main ingredient of BOTOX is highly purified botulinum toxin type a,a nerve':'The main ingredient of BOTOX is highly purified botulinum toxin type a,a nerve',
      'conduction blocking agent used to treat overactive muscles. BOTOX is a natural, purified protein that relaxes the muscles that cause wrinkles, resulting in smoother, younger, and more youthful skin and the disappearance of dynamic wrinkles.':'conduction blocking agent used to treat overactive muscles. BOTOX is a natural, purified protein that relaxes the muscles that cause wrinkles, resulting in smoother, younger, and more youthful skin and the disappearance of dynamic wrinkles.',
      'Restylane treatment does not take long. It takes about 15-45 minutes depending on the area and indications.':'Restylane treatment does not take long. It takes about 15-45 minutes depending on the area and indications.',
      'Hyaluronic acid has been certified by the FDA and SFDA in China. Hyaluronic acid is non-toxic and has excellent human compatibility and will not cause allergic reactions in humans.':'Hyaluronic acid has been certified by the FDA and SFDA in China. Hyaluronic acid is non-toxic and has excellent human compatibility and will not cause allergic reactions in humans.',
      "Hyaluronic acid has three molecular sizes: small, medium and large. Large and medium molecules can be injected into the nose, chin, tear trough and other locations, and it is necessary to confirm the injection site and measurement according to the doctor's instruction.":"Hyaluronic acid has three molecular sizes: small, medium and large. Large and medium molecules can be injected into the nose, chin, tear trough and other locations, and it is necessary to confirm the injection site and measurement according to the doctor's instruction.",
      'Hyaluronic acid can be maintained for about nine to twelve months, but the specific maintenance time should be determined by the actual situation. Since Hyaluronic acid is biologically soluble and can be discharged with human metabolism, the duration of maintenance varies from person to person.':'Hyaluronic acid can be maintained for about nine to twelve months, but the specific maintenance time should be determined by the actual situation. Since Hyaluronic acid is biologically soluble and can be discharged with human metabolism, the duration of maintenance varies from person to person.',
      "RETYLANE":"RETYLANE",
      "ANTI-AGING ARTIFACT":"ANTI-AGING ARTIFACT",
      'Solid "Molecular Particles"':'Solid "Molecular Particles"',
      'Small particles of cross-linked hyaluronic acid with a strong mesh structure, more suitable for dynamic lines and multi-site filling.':'Small particles of cross-linked hyaluronic acid with a strong mesh structure, more suitable for dynamic lines and multi-site filling.',
      'Integration of':'Integration of',
      '"water locking and hydration"':'"water locking and hydration"',
      'While stabilizing the shape, it is gradually absorbed by the dermis and fused with the body hyaluronic acid, capturing and locking a large number of water molecules.':'While stabilizing the shape, it is gradually absorbed by the dermis and fused with the body hyaluronic acid, capturing and locking a large number of water molecules.',
      'Remodeling"dermal structure"':'Remodeling"dermal structure"',
      'After reaching the dermis, it activates the growth of fibroblasts, promotes collagen renewal and enhances skin quality.':'After reaching the dermis, it activates the growth of fibroblasts, promotes collagen renewal and enhances skin quality.',
      'The main ingredient of Lizulan Black, poly (poly) nucleotides, can effectively improve the internal structure of the skin, enhance the regenerative ability of cellular tissues, and effectively solve many problems such as dullness, damage, wrinkles, aging and acne of the skin.': 'The main ingredient of Lizulan Black, poly (poly) nucleotides, can effectively improve the internal structure of the skin, enhance the regenerative ability of cellular tissues, and effectively solve many problems such as dullness, damage, wrinkles, aging and acne of the skin.',
      'However, if the skin dehydration is more serious or fine lines appear, you need to inject it 2~3 times before you can see the obvious effect. Since the effect of hydrafacial injection usually lasts for about half a year or a year, continuous injection is needed to continue the improvement':'However, if the skin dehydration is more serious or fine lines appear, you need to inject it 2~3 times before you can see the obvious effect. Since the effect of hydrafacial injection usually lasts for about half a year or a year, continuous injection is needed to continue the improvement.',
      'Lizulan Hydrafacial can promote the regeneration of skin cells and have a repairing effect on damaged skin, which can make the skin full of elasticity, improve sagging skin, lighten wrinkles, and bring back the skin to firmness.':'Lizulan Hydrafacial can promote the regeneration of skin cells and have a repairing effect on damaged skin, which can make the skin full of elasticity, improve sagging skin, lighten wrinkles, and bring back the skin to firmness.',
      "For the various skin problems faced by different people, Lizulan's product familyprovides a series of multi-dimensional solutions.":"For the various skin problems faced by different people, Lizulan's product familyprovides a series of multi-dimensional solutions.",
      'Multi-dimensional "Powerful Energy"':'Multi-dimensional "Powerful Energy"',
      'High-end "Mesotherapy Injection"':'High-end "Mesotherapy Injection"',
      'Rapid "Good Diffusion"':'Rapid "Good Diffusion"',
      'Adding patented polynucleotides to traditional hydration injections to effectively increase skin regeneration and improve internal skin circulation.':'Adding patented polynucleotides to traditional hydration injections to effectively increase skin regeneration and improve internal skin circulation.',
      'Low viscosity leading injections have better diffusion and longer retention, which are quickly absorbed at the injection site and return to the"mother nature" state within 24 hours.':'Low viscosity leading injections have better diffusion and longer retention, which are quickly absorbed at the injection site and return to the"mother nature" state within 24 hours.',
      'REJURAN':'REJURAN',
      '"SHUIGUANG':'"SHUIGUANG',
      'THE FIRST STEP IN MEDICAL BEAUTY"':'THE FIRST STEP IN MEDICAL BEAUTY"',
      'HONEYED JOY':'HONEYED JOY',
      "GIRL'S ANOTHER FACE":"GIRL'S ANOTHER FACE",

      'Comprehensive "5 Criteria"':'Comprehensive "5 Criteria"',
      "Shape, color, tightness, moistness, and technique, taking into account medical,aesthetic, and pleasure, for high-end intimate care of women's health.":"Shape, color, tightness, moistness, and technique, taking into account medical,aesthetic, and pleasure, for high-end intimate care of women's health.",
      'All intimate doctors have rich clinical experience, combining doctors, technology and aesthetics to make the experience more secure.':'All intimate doctors have rich clinical experience, combining doctors, technology and aesthetics to make the experience more secure.',
      'Senior "Gynecologists"':'Senior "Gynecologists"',
      '"Customized Program"':'"Customized Program"',
      'Starting from a private and comfortable 1-to-1 consultation with a female doctor, through a more comprehensive professional test, we will customize an intimate program for each female customer until the end of the intimate program, with VIP care to escort the treatment throughout the whole process.':'Starting from a private and comfortable 1-to-1 consultation with a female doctor, through a more comprehensive professional test, we will customize an intimate program for each female customer until the end of the intimate program, with VIP care to escort the treatment throughout the whole process.',

      'DEEP CLEANING':'DEEP CLEANING',
      'MAINTENANCE':'MAINTENANCE',
      'STARTING FROM PURIFYING PORES':'STARTING FROM PURIFYING PORES',
      'Soft "Pore Unblocking"': 'Soft "Pore Unblocking"',
      "Cleanses deep pores, accelerates the shedding of dead skin cells, improves blackheads, and regulates the skin's oil and water balance.":"Cleanses deep pores, accelerates the shedding of dead skin cells, improves blackheads, and regulates the skin's oil and water balance.",
      'Cleanse in one go':'Cleanse in one go',
      'Only when the skin is allowed to"breathe" can it take in external supplies, allowing maintenance to be "twice as effective".':'Only when the skin is allowed to"breathe" can it take in external supplies, allowing maintenance to be "twice as effective".',
      
      'SKIN MANAGEMENT':'SKIN MANAGEMENT',
      'CUSTOMIZED FOR YOUR SKIN':'CUSTOMIZED FOR YOUR SKIN',
      'Top Luxury Skincare':'Top Luxury Skincare',
      "Combining two of the world's leading skincare brands, the collection of authoritative patents and advanced technology for skin empowerment.":"Combining two of the world's leading skincare brands, the collection of authoritative patents and advanced technology for skin empowerment.",
      'Huge amount of "user approval"':'Huge amount of "user approval"',
      "Covering 100+ countries around the world, each product has passed the authoritative clinical certification and millions of users' approval feedback.":"Covering 100+ countries around the world, each product has passed the authoritative clinical certification and millions of users' approval feedback.",
      'Effective "Comfortable Skin Care"':'Effective "Comfortable Skin Care"',
      'Understanding skin needs while choosing targeted skincare products to improve skin condition."':'Understanding skin needs while choosing targeted skincare products to improve skin condition."',
"Copyright © 2023 Vernal Eternity Aesthetic clinic.":"Copyright © 2023 Vernal Eternity Aesthetic clinic.",
"Designed by":"Designed by",
"HANSN":"HANSN",
" BRAND ":"BRAND",
    },







  "zn": {
    "Copyright © 2023 Vernal Eternity Aesthetic clinic.":"版权所有 © 2023 永恒的青春美容诊所.",
"Designed by":"由",
"HANSN":"汉生 ",
" BRAND ":"品牌设计 ",

    'SKIN MANAGEMENT':'皮肤管理',
    'CUSTOMIZED FOR YOUR SKIN':'为你量肤定制',
    'Top Luxury Skincare':'严选「顶奢护肤」',
    "Combining two of the world's leading skincare brands, the collection of authoritative patents and advanced technology for skin empowerment.":"联合世界前沿两大护肤品牌，集合权威专利和先进科技为肌肤赋能。",
    'Huge amount of "user approval"':'海量「用户认同」',
    "Covering 100+ countries around the world, each product has passed the authoritative clinical certification and millions of users' approval feedback.":"覆盖全球100+国家，每款产品均通过权威临床认证，百万级用户认可反馈。",
    'Effective "Comfortable Skin Care"':'有效「舒适护肤」',
    'Understanding skin needs while choosing targeted skincare products to improve skin condition."':'了解肌肤诉求的同时选择针对性护肤品，从而改善皮肤状态。',


    'Soft "Pore Unblocking"': '柔性「疏通毛孔」',
    "Cleanses deep pores, accelerates the shedding of dead skin cells, improves blackheads, and regulates the skin's oil and water balance.":"扫除毛孔深处油脂、加速废角质脱落，改善黑头，调节肌肤水油平衡。",
    'Cleanse in one go':'一次「清洁到位」',
    'Only when the skin is allowed to"breathe" can it take in external supplies, allowing maintenance to be "twice as effective".':'只有让皮肤“呼吸”，才能摄取外界补给，让保养“事半功倍”。',
    
    'DEEP CLEANING':'深层清洁',
    'MAINTENANCE':'养护，从净化毛孔开始',
    'STARTING FROM PURIFYING PORES':' ',

    'Comprehensive "5 Criteria"':'综合「5大标准」',
    "Shape, color, tightness, moistness, and technique, taking into account medical,aesthetic, and pleasure, for high-end intimate care of women's health.":"形、色、紧、润、技，兼顾医学、美学、愉悦度，为女性健康进行高端私密护理。",
    'All intimate doctors have rich clinical experience, combining doctors, technology and aesthetics to make the experience more secure.':'私密医师均具备丰富的临床经验，将医生、技术及美学深度结合，让体验更加安心。',
    'Senior "Gynecologists"':'资深「妇科医生」',
    'Exclusive "Customized Program" ':'专属「定制方案」',
    'Starting from a private and comfortable 1-to-1 consultation with a female doctor, through a more comprehensive professional test, we will customize an intimate program for each female customer until the end of the intimate program, with VIP care to escort the treatment throughout the whole process.':'从隐私舒适的1对1女医师面诊开始，通过更全面的专业检测，为每一位女性顾客定制专属私密方案，直至私密项目结束，全程配备VIP贴心护理为治疗保驾护航。',





    'HONEYED JOY':'密享会',
    "GIRL'S ANOTHER FACE":"妳的另一张脸",
    'REJURAN':'丽珠兰',
    '"SHUIGUANG':'水光—医美第一步',
    'THE FIRST STEP IN MEDICAL BEAUTY"':' ',
    'High-end "Mesotherapy Injection"':'高端「中胚层针剂」',
    'Rapid "Good Diffusion"':'快速「良好扩散」',
    'Adding patented polynucleotides to traditional hydration injections to effectively increase skin regeneration and improve internal skin circulation.':'传统水光针基础上加入专利多核苷酸，有效提高肌肤再生能力，改善皮肤内循环。',
    'Low viscosity leading injections have better diffusion and longer retention, which are quickly absorbed at the injection site and return to the"mother nature" state within 24 hours.':'低黏度领先针剂具备更优扩散性及更长留存性，在注射部位快速吸收，24小时内回归“妈生”状态。',
    "For the various skin problems faced by different people, Lizulan's product familyprovides a series of multi-dimensional solutions.":"针对不同人群面对的多种肌肤问题，丽珠兰产品家族提供系列化多维解决方案。",
    'Multi-dimensional "Powerful Energy"':'多维「强大能量」',
    'The main ingredient of Lizulan Black, poly (poly) nucleotides, can effectively improve the internal structure of the skin, enhance the regenerative ability of cellular tissues, and effectively solve many problems such as dullness, damage, wrinkles, aging and acne of the skin.': '丽珠兰黑的其主要成分聚（多）核苷酸，能有效改善皮肤内部结构，增强细胞组织的再生能力，有效解决皮肤暗淡、损伤、皱纹、老化和痤疮等多种问题。',
    'However, if the skin dehydration is more serious or fine lines appear, you need to inject it 2~3 times before you can see the obvious effect. Since the effect of hydrafacial injection usually lasts for about half a year or a year, continuous injection is needed to continue the improvement':'丽珠兰水光针打一次但具有一定的改善效果，但是对于皮肤缺水比较严重或者是出现细纹时，需要注射2~3次之后可以看到明显的效果。因为水光针效果通常维持半年或者是一年左右，所以需要持续注射来继续改善。',
    'Lizulan Hydrafacial can promote the regeneration of skin cells and have a repairing effect on damaged skin, which can make the skin full of elasticity, improve sagging skin, lighten wrinkles, and bring back the skin to firmness.':'丽珠兰水光针可促进再生皮肤细胞，对受损的皮肤有修复作用，可以让皮肤充满弹性，改善松弛的肌肤，淡化皱纹，让皮肤重回紧致。',
    'Remodeling"dermal structure"':'重塑「真皮结构」',
    'After reaching the dermis, it activates the growth of fibroblasts, promotes collagen renewal and enhances skin quality.':'抵达真皮层后激活纤维细胞生长，促进胶原蛋白新生，提升肌肤质量。',
    'While stabilizing the shape, it is gradually absorbed by the dermis and fused with the body hyaluronic acid, capturing and locking a large number of water molecules.':'稳固塑形的同时，逐步被真皮层吸收与本体玻尿酸相融合，捕捉锁定大量水分子',
    'Solid "Molecular Particles"':'稳固「分子颗粒」',
    'Small particles of cross-linked hyaluronic acid with a strong mesh structure, more suitable for dynamic lines and multi-site filling.':'小颗粒交联透明质酸，拥有强大的网状结构，更适合动态纹路及多部位填充。',
    'Integration of':' 融合「锁水补水」',
    '"water locking and hydration"':' ',
    '"Instantly visible" effect':'效果「即时可见」',
    'Fastest "10 minutes" completion, no recovery period, visible results in 3-7 days.':'最快“10分钟”完成，无需恢复期，3-7日效果明显可见。',
    'The probability of side effects of BOTOX is very small and short-lived. Slight drooping of the corners of the eyes or drooping of the eyebrows occurs in 1-5% of cases and usually disappears within 2 weeks. And a few patients may experience slight bruising.':'保妥适的副作用出现概率非常小，也是短时间的。有1%-5%的病例出现轻微的眼角下垂或眉毛下垂，通常于2周内消失。而少数患者会出现轻微的淤青。',
    "BOTOX can be widely used to eliminate: frown lines between the eyebrows, horizontal head lines, crow's feet or laugh lines at the corners of the eyes, for wrinkles between the nose and lips, periorbital lines, and chin depressions or folds, etc. It has good effects. It can also be used for face slimming treatment.":"保妥适可广泛用于消除：眉间的皱眉纹、水平的抬头纹、眼角的鱼尾纹或笑纹，对于鼻与唇间皱纹、唇周纹，以及下巴凹陷或皱折等，均有良好效果。也可用于瘦脸治疗。",
    "BOTOX will take effect in about 3-7 days after injection, but the exact time varies from person to person and is generally determined by the rate of local absorption. If the body's metabolism is fast and the local absorption rate is relatively fast, the effect may be seen in about 3 days.":"保妥适注射后3-7天左右的时间就会起效，但具体时间还需因人而异，一般要根据局部吸收的速度判定。如果机体新陈代谢较快，局部吸收速度比较快，可能在3天左右的时间就会看见效果。",
    'The main ingredient of BOTOX is highly purified botulinum toxin type a,a nerve':'保妥适（BOTOX）的主成分为高度纯化的肉毒杆菌素a型，是一种神经传导的阻断剂，用以治疗过度活跃的肌肉。',
    'conduction blocking agent used to treat overactive muscles. BOTOX is a natural, purified protein that relaxes the muscles that cause wrinkles, resulting in smoother, younger, and more youthful skin and the disappearance of dynamic wrinkles.':'保妥适是一种天然、纯化的蛋白质，可让造成皱纹的肌肉放松，肌肤变得平滑、年轻、而且更富有青春活力，使动态性的皱纹消失。',
    'Restylane treatment does not take long. It takes about 15-45 minutes depending on the area and indications.':'瑞兰治疗时间不长。根据面积和适应症，需要大约15-45分钟。',
    'Hyaluronic acid has been certified by the FDA and SFDA in China. Hyaluronic acid is non-toxic and has excellent human compatibility and will not cause allergic reactions in humans.':'瑞兰玻尿酸目前已经获得了美国FDA认证和中国SFDA双认证，瑞兰玻尿酸的玻尿酸材料无毒，人体兼容性极好，不会引起人体过敏反应的特点，注射后稳定并且自然，是一种可被人体正常代谢吸收的玻尿酸，安全性高。',
    "Hyaluronic acid has three molecular sizes: small, medium and large. Large and medium molecules can be injected into the nose, chin, tear trough and other locations, and it is necessary to confirm the injection site and measurement according to the doctor's instruction.":"瑞兰玻尿酸有小、中、大三种分子大小，大、中分子可以注射鼻部、下巴、泪沟等位置，具体需要根据医生指导确认注射部位与计量。",
    'Hyaluronic acid can be maintained for about nine to twelve months, but the specific maintenance time should be determined by the actual situation. Since Hyaluronic acid is biologically soluble and can be discharged with human metabolism, the duration of maintenance varies from person to person.':'瑞兰玻尿酸大概可以维持九个月到十二个月左右，具体的维持时间还是需要根本实际情况来决定。因瑞兰玻尿酸具有生物溶解性，可随人体新陈代谢排出，所以维持时效因人而异。',
    "RETYLANE":"瑞兰",
    "ANTI-AGING ARTIFACT":"抗龄神器",
    'Lower dispersion of 0.44cm, more natural aesthetic effect, precise and controllable wrinkle removal effect.':'更低的0.44cm弥散度，美学效果更加自然，除皱效果精准可控。',
    '30 years of international clinical use, quality assurance, 0 rejection injection for more safety.':'30年国际临床使用，品质保障，0排异性注射更加安全。',
    'The only "SFDA certification"':'唯一「SFDA认证」',
    "Comprehensive":"全面「精准除皱」",
    '"Precision Wrinkle Reduction"':' ',
    "There are no side effects, but bruising or swelling may occur within a few days after the project depending on the individual's body type, and there may be tightness and swelling in the filler area, which is normal.":"乔雅登填充剂比胶原蛋白更容易塑形和塑造，可显着改善结块和不均匀形状，其使用寿命也比胶原蛋白的 3 倍，并且比传统的填充剂更柔软、更自然。",
    "The small amount of lidocaine contained in the Juvederm filler reduces the pain of the injection process to a certain extent. Most of the patients who had Juvederm filler injections reported that the pain level was tolerable.":"因乔雅登填充剂中含有少量利多卡因，可以一定程度上减少注射过程中的疼痛感。多数乔雅登填充剂注射患者反馈，其疼痛程度可以承受。",
    "The results of a Juvederm filler treatment last approximately 12 to 18 months, depending on the area being treated, the filler dose, and individual skin conditions.":"乔雅登填充剂治疗的效果大概可以维持 12 至 18 个月，具体取决于治疗的区域、填充剂量以及个人皮肤状况。",
    "Juvederm fillers are easier to shape and mold than collagen, provide significant improvement in clumping and uneven shape, last 3 times longer than collagen, and are softer and more natural than traditional fillers.":"乔雅登填充剂比胶原蛋白更容易塑形和塑造，可显着改善结块和不均匀形状，其使用寿命也比胶原蛋白的 3 倍，并且比传统的填充剂更柔软、更自然。",
    'Patented "Exclusive Technology"':'专利「独有技术」',
    "HYLACROSS technology, which is silky smooth to the touch after injection, high integration, strong shaping power, and long maintenance time.":"HYLACROSS技术，注射后触感丝滑，融合性高，塑型力强，形态维持时间长。",
    'Gentle "microbial fermentation"':'温和「微生物发酵」',
    "Microbial fermentation method is used, and contains a small amount of numbing agent, which is safe and reduces the pain of injection.":"采用微生物发酵法，并含有微量麻剂，安全的同时降低注射痛感。",
    'BOTOX':'保妥适',
    '"STAR':'明星',
    "'S":"的",
    'CAUTION"':'小心机',
    'Natural "Safety Gel"':'天然「安全凝胶」',
    "The first hyaluronic acid to be approved by the FDA in the U.S., followed by CE certification in Europe and CFDA certification in China.":"首个通过美国FDA认证的玻尿酸，后通过欧洲CE认证、中国CFDA认证。",
    'CONTENT': '满足',
    'TO THE REAL' : '肌肤真',
    'DEMANDS OF SKIN':'实诉求',
    'ENJOYMENT':"安享 ",
    'MEDICAL GRADE':"医疗",
    'PROFESSIONAL':"级专业",
    'NURSING':"护理",
    'ACCURATE ':"精准", 
    'SPECTRUM':" 高效焕",
    'REVITALIZE SKIN':"活肌肤",
    'VE AESTHETIC':" VE",
    'PROGRAM':"美学方案",
    'AESTHETIC INSIGHT':"美学洞察",
    'Test the 6 major skin health indicators':"检测6大肌肤健康指数",
    'Give you the confidence and direction to treat.':"  给您治疗的信心与方向",
    'REALLY NEEDS':"找到所需",
    'One-on-One Guest Consultant':"一对一客席顾问",
    'Precise understanding of your beauty needs.':"精准了解您的变美诉求",
    'ONE-STOP SOLUTION':"多维管理",
    'Full range of medical aesthetic programs':"全品类医学美容项目",
    'Multi-dimensional solutions from the surface to the inside.':"由表至里的多维解决方案",
    'LIFE AND SPACE':"人与空间",
    'A healing and quiet salon treatment room':"治愈静谧的沙龙诊疗室", 
    'Enjoy the original power of beauty in peaceful rest.':"安心休憩中享受美的原生之力",
    'NATURE TO BEAUTY':"生美平衡",
    'In a more efficient and natural way':"用更加高效自然的方式", 
    'Let life and aesthetics balance and symbiosis.':"让生活与美学平衡共生",
    'PRODUCT ALLIANCE':"产品联盟",
    'LOCATE US':"联系我们",  
    '241 River Valley Road':"河谷路241号",
    'Gianurn Building #03-01':"仰思大厦 #03-01",
    'Singapore 238298':"新加坡238298",
    'HAVE A QUESTION？':" 有问题吗？",
    'Speak to our VE therapeutics':"立即与我们的专家交谈！",
    'experts today！':"  ",
    'EMAIL':"电子邮件",   
    'PHONE':"电话",
    'NAME':"名字 ",
    'I HAVE A QUESTION ABOUT... ':"我有一个关于... ",
    'TELL US MORE!':"告诉我们更多! ",
    'Select About':"选择关于",
    'SUBMIT':"提交",
    'VIEW MORE':"了解更多信息",
    'SELECT':"VE 甄选",
    'VE BRAND':"VE 品牌",
    'POWER INSTRUMENT':"高效仪器",
    'MEDICAL INJECTIONS':"改善针剂",
    'V-CURVE MAINTAIN':"V曲线养护",
    'PRECISE SKIN CARE':"精准护肤",
    'EXPLORE':"美好探索",
    'SPECIALTY':"专业领域",
    'JOIN US':"加入我们",
    'VE CLUB':"VE 俱乐部",
    'NEW CONCEPT':"理念焕新",
    'VE Medical Aesthetics, as the creator of the "light" medical aesthetic industry, will bring you a':"VE医疗美容，作为「轻」医美行业缔造者，",
    'With "LOVE&ENJOY" as our brand concept, we are committed to bring you a more quality life,':" 我们以“LOVE&ENJOY”为品牌理念，",
    'confidence and courage.':"致力带给您更有质感的生活、自信与勇气。",
    'warm and highly non-surgical rejuvenation experience.':"将为每一位顾客带来有温度、有高度的非手术年轻化体验。",
    'Create a more dynamic medical aesthetic industry.':"打造更富活力的医美业态",
    '"FOUR STEPS"':'"四大步调"',
    'STEP':'步骤',
    'THE MEDICAL CONCEPT':'人本质上的医学理念',
    'OF HUMAN NATURE':' ',
    'advanced technology to make beauty safer and more efficient.':'让美丽更安全、更高效。',
    'Combining traditional medical beauty with':'将传统医美与先进科技进行相结合',
    '"DOUBLE BEAUTY" SYMBIOSIS':' ',
    'A NEW CONCEPT OF':'“双美”共生的全新理念',
    'Experience non-invasive medical aesthetics, bringingmedical beauty closer to life.':'体验无创医疗美容，让医美贴近生活。',
    'CREATE A BETTER SOLUTION':'创造医美更优解决式',
    'FOR MEDICAL BEAUTY':' ',
    "Recognized by the world's leading high-end":"严选世界高端护肤品牌、前沿光电设备",
    'brands, one-stop solution to your skin problems.':'一站式解决您的肌肤困扰。',
    'PERSONAL CUSTOMIZATION':'  私人定制与智慧服务',
    'AND SMART SERVICE':'   ',
    'the First Time to Every Time.':'  从第一次到每一次，打造定制化抗衰策略 ',
    'Create a Customized Anti-Aging Strategy From':'   ',
    'BRAND':'  品牌特色  ',
    'SPECIALITY':'   ',
    "Strictly select the world's leading FDA-certified photoelectric beauty equipment.":" 严选世界领先FDA认证光电美容设备，",
    'Experience higher-end non-surgical "light" medical beauty.':'     体验更高端的非手术「轻」医美。',
    'HIGHLIGHT OF TIME':' 点亮时间——高端 ',
    'FULL OF VERVE':'尽显神韵——高效  ',
    'careFeel the instant beauty transformation.':'感受即时可见的美丽蜕变。',
    "From medical grade injections to daily skin":" 从医疗级注射到日常肌肤管理， ",
    'AESTHETIC GUIDANCE':'审美引导——年轻化  ',
    'Let your unique temperament and beauty':'让你独一无二的气质与美貌并存，',
    "coexistLet the change follow the trend of the times.":"让改变紧随时代潮流。",
    'INSTANT VISIBILITY ':'即显本色——生活化  ',
    'In their own pace of lifeSolve most skin':'在属于自己的生活节奏中，',
    "problems":"解决肌肤面临大多数问题。",
    'BRAND ':'  品牌溯源  ',
    'TRACEABILITY':'   ',
    'positioned the concept of "light medical beauty"to create a new generation of medical beauty brand.':'打造新生代医美品牌。',
    'The origin of the brand, the principal of VE':'VE品牌概念提出，主理人定位「轻医美」概念',
    'To obtain a license to practice medicine for ':'为了打造更安全、更值得信赖的医学美容品',
    'safer and more effective medical aesthetics.':'牌，获得执业医师资格。',
    "Authorized by dibi brand, authorized by SKEYNDOR brand.":"获得DIBI品牌授权、获得雪曼婷品牌授权。",
    "Received medical accreditation,located in the heart of Singapore, and implemented the construction of the first flagship store.":" 获得医疗资质认可、选址新加坡中心，落实建设首家旗舰店",
    "":"设首家旗舰店",
    'grade equipment, expert endorsement team formation completed.':'团队组建完成。',
    'Introduction of fda-approved medical-':'着手引进FDA批准医疗级设备，专家级背书',
    'Recognized by leading-edge optoelectronic':'获得美国、以色列、中国众多国家的高端光',
    'equipment brands in the United States, Israel, and multiple countries in China.':'电设备品牌认可。',
    'Professional light medical beauty service':'专业轻医美服务团队组建完成，工作人员于',
    "team, top beauty academy training ended.":"顶尖美容学院完成所有课程。",
    "Focusing on non-surgical rejuvenation, Ve's":"专注于非手术年轻化、让医美更贴近生活的",
    'emerging light medical aesthetic institution officially opened.':'VE新兴轻医美机构正式开业。',
    "No. Hive picosecond can penetrate into the deeper layers of the skin, and only the melanin particles are affected, so there is no damage to the surface skin.":'不会。蜂巢皮秒能够穿透表层皮肤进入深层皮肤，只对黑色素颗粒产生作用，表层皮肤不会有损伤，所以术后肌肤上不会有疤痕。',
    "EQUIPMENT":"我们的设备",
    "The equipment is FDA approved,thus":"我们的设备，设备通过FDA认证，从而确保",
    "ensuring that your choice is safe andeffective.":"您的选择安全有效。",
    "beauty industry, radiating to more than 100 countries around the world and integrating the world's latest technology.":"100多个国家，融合世界最新技术。",
    "We select the world's biggest names in the":"我们的产品，严选世界美业大牌，辐射全球",
    "PRODUCTS":"我们的产品",
    "DOCTORS":"我们的医生",
    "Preferred team of doctors, all qualified topractice medicine in Singapore, to escort your beautyjourney safely.":"我们的医生，优选医生团队，均获得新加坡执业医师资格，为你的美丽之旅安全护航。",
    "VE STANDARD":"VE 理念",
    "VE adheres to the original intention of providing sincere service to customers, making each treatment safe and effective,and reassuring every customer.":"VE秉承对顾客真诚服务、让每次治疗安全有效、令每一位顾客放心的初衷，引进先进的服务模式， 打造专业化和人性化尊享服务。 ",
    "From the front desk, consultants, doctors, nurses to post-operative services, we create the ultimate experience process from smile greeting, professional consultation & treatment & service, personalized service & personal responsibility, tracking service, private private butler service, transparent price system, etc.":"从前台、咨询师、医生、护理师到术后服务等，为用户打造从微笑迎接、专业咨询&治疗&服务、专人专服&专人负责、跟踪式服务、私人私密管家服务、透明价格体系等极致体验流程。",
    "ALLIANCE":"美学方案",
    " PRODUCT":" VE ",
    " ALLIANCE ":" ",
    " PRODUCT ":"产品联盟",
    "VE brand is committed to bringing quality,safety and comfort to the new generation of light luxury beauty services, combining technology and natural ingredients, integrating black technology and cutting-edge beauty equipment.":"VE品牌致力于用心为新生代群体，带来品质、安全、舒适的轻奢美肤服务，将科技与天然成分深度结合，将黑科技与前沿美容仪器相融合。",
    "Points can be accumulated based on consumption, and accumulated points can be redeemed for daily items.":"可根据消费进行积分累积，累计积分可用于日常项目兑换。",
    "POINTS REDEMPTION":'积分兑换',
    "With the world's":"携世界知名品牌 ， ",
    'leading brands to give customers a "lighter" more "efficient" and "healthier" skin experience.':"给予客户更“轻”更“高效”更“健康”的肌肤体验。",
    "VALUE ADDED":"增值服务",
    "SERVICES":"  ",
    "Have a beauty personal consultant and exclusive member discounts.":"拥有美容私人顾问，会员专属折扣。",
    "OFF-LINE ACTIVITIES":" 线下活动 ",
    "PREMIUM EXPERIENCE":"  尊享体验",
    "Branded afternoon tea events, beauty mini-classes and other offline activities.":" 品牌下午茶活动，美容小课堂等线下活动。 ", 
    "Birthday gift, new project experience, vip VIP room, free drink afternoon tea.":"  生日赠礼，新项目体验，vip贵宾室，免费饮品下午茶。",
    "PRECISE":"精准护肤 ",
    "SKIN CARE":" ",
    "V-CURVE":"V曲线养护 ",
    "MAINTAIN":" ",
    "MEDICAL":"改善针剂 ",
    "INJECTIONS":" ",
    "POWER":"高效仪器 ",
    "INSTRUMENT":"  ",
    "PICOSURE":"蜂巢皮秒",
    "IN THE MEDICAL BEAUTY INDUSTRY":"  ",
    '"BLACK TECHNOLOGY"':'医美界的“黑科技”',
    'FDA&NMPA dual certification,Unique Focus diffractive lens,Precise shooting of melanin particles.':'FDA&NMPA双重认证，独有的Focus聚焦衍射透镜，精准射击黑色素颗粒。',
    'FOCUS ON "HONEYCOMB LENS"':'专注「蜂巢透镜」',
    'A picosecond equals one trillionth of a second,Faster speed instantly crushes pigment technology,shorten treatment time.':'一皮秒等于一万亿分之一秒，更快速度瞬间击碎色素科技，缩短治疗时间。',
    'INSTANTANEOUS "PIGMENT BLAST"':'瞬时「色素爆破」',
    'Unique 755nm wavelength, While reducing heatdamage to the skin, Deter melanin activity, Reduce therisk of returning to black.':'独特的755nm波长，减少对皮肤热伤害的同时，震慑黑色素活跃，降低返黑风险。',
    'ENJOY "CLEAR, GLOWING SKIN"':'恒享「清透美肌」',
    'Initiate cell release of protein, let the skin producenew collagen and elastin from within, Gently smoothes the skin.':'启动细胞释放蛋白质，让皮肤自内产生新的胶原蛋白和弹性蛋白，轻柔抚平肌肤。',
    'PROMOTE "PLANTAR NEOGENESIS"':'促进「肌底新生」',
    'FREQUENTLY ASKED QUESTIONS':'项目常见问题',
    'RECOMMENDED PRODUCTS':'推荐产品',
    "Honeycomb picosecond uses a laser and a honeycomb lens together to aggregate most of the laser energy and shatter melanin in different skin layers using targeted treatment. Honeycomb picosecond can":"蜂巢皮秒将激光和蜂巢透镜搭配使用，把大部分的镭射能量进行聚集，利用靶向治疗的方式震碎不同皮肤层的黑色素。",
    "promote the regeneration of collagen and collagen fibers, speed up the body's metabolism, and solve the deep skin melanin problem from the root, to achieve the purpose of whitening and rejuvenation.":"蜂巢皮秒可以促进胶原蛋白和胶原纤维的再生，加快机体的新陈代谢，从根源上解决深层皮肤黑色素问题，实现美白和嫩肤的目的。",
    "All laser treatments are not guaranteed to be completely free of re-blackening, but the risk of re- blackening is much lower than that of traditional lasers because of the small damage to the skin":"所有激光治疗都不能保证完全不反黑，蜂巢皮秒对于皮肤伤害小，红肿返营地，返黑风险较传统激光降低很多。",
    "and the return of redness and swelling to the camp. However, after surgery, you need to strengthen sun protection measures and do a good job of hydration. If the post-operative care is not proper, there is also a certain degree of risk of re-tanning.":"但是术后需要加强防晒措施，做好补水工作。如果术后护理不当，也存在一定程度的返黑风险。",
    "Honeycomb picosecond can fade scars, for deep scar depressions it needs to be combined with fractional laser in conjunction with the treatment.":"蜂巢皮秒可以淡化疤痕，对于深的疤痕凹陷需要结合点阵激光配合治疗。",
    "There are many factors that affect the results of Honeycomb picosecond surgery, mainly the mode, number of times, post-operative care and so on. Generally, the results can be seen within 7 days after Honeycomb picosecond surgery because the skin needs a repair cycle.":"蜂巢皮秒手术效果的影响因素非常多，主要有模式、次数、术后护理等等。一般情况下，蜂巢皮秒术后7天内可以看到效果，因为皮肤需要修复周期。",
    "Dual micro and macro focus, fast and precise sensor for sensitive areas.":"微观宏观双重聚焦，快速精密传感器适用敏感区域。",
    'Dual "Focus Technology"':'双重「聚焦科技」',
    'Convenient and safe treatment with immediate "lunchtime" results.':'“午餐时间”即刻见效的便捷安全治疗过程。',
    'Highly effective "non-invasive lift"':'高效「无创提拉」',
    'From NASA "Infrared Skin Beauty"':'源自NASA「红外美肤」',
    'Top "Stable Energy"':'顶级「稳定能量」',
    '360 degree "Painless"':'360度「全程无痛」',
    'Three main functions':'三大功能',
    'Deep cleansing:':'',
    'cleanse epidermis of sebum, dirt and excess keratin.':'深层清洁：清洁表皮皮脂、脏污及多余角质 ',
    'Regulate keratin:':' ',
    'increase the thickness of the epidermis and repair the skin barrier.':'调节角质：增加表真皮厚度，修复肌肤屏障。',
    "LIFT SKIN, CHANGE AGE":"提升皮肤，改变年龄",
    "ULTRAFORMER MPT":"7D 聚拉提",
    "Multi-depth probes quickly address the different depths and levels of skin aging.":"多深度探头快速解决不同深度、多层次的皮肤老化现象。",
    "Rejuvenation":"展现「年轻状态」",
    "The 7d Poly Latti can show the tightening and lifting effect quickly after the treatment, but the effect is not permanent, generally can maintain 2~3 years, the maintenance time is related to the doctor's operation level, the patient's skin condition and post-operative care. The interval between 7d Poly Lattis should not be less than two years.":"7D聚拉提可在治疗后快速显现紧缩提拉效果，但效果不是永久的，一般可维持2~3年，维持时间和医生操作水平、患者的皮肤情况及术后护理有关。做7D聚拉提的间隔时间不能低于两年。",
    "It is normal to experience dryness after surgery, as long as the cosmetic device is implemented through thermal excitation, it will bring about a little damage to the moisture layer and evaporate a large amount of water under the skin at the same time.":"术后出现干燥的现象是正常的，只要是通过热能激发实施的美容仪器，都会带来一下保湿层的损坏，同时蒸发皮下大量的水分。",
    "The 7d Poly Latti is performed by focusing ultrasonic energy on the dermis and SMAS fascia layer of the face, heating the tissue to 65°C~75°C. Therefore, it is better to say that it is painful rather than hot; most people do not feel the pain of it, and if the pain of the previous machine lift is ten points, the 7d Poly Latti is less than five points.":"7D聚拉提是透过聚焦超声波能量的作用，在脸部真皮层及SMAS筋膜层，将组织加热到65°C~75°C，所以说，与其说热不如说是痛感；大部分人感觉不到它的痛感，若说以往的机器提拉痛感有十分，那7D聚拉提便为五分以下，不能说完全无感觉，但至少是舒适的选择。",
    "1. After the 7d Poly Latti, you must do a good job of moisturizing and nursing, it is recommended to put on a mask for three days, and try not to wear makeup.":"1、做完7D聚拉提之后，一定要做好保湿、护理工作，建议连敷三天的面膜，另外尽量不要化妆;",
    "2. After the treatment, you should avoid going in and out of high heat environment, such as sauna, sauna, etc., so as not to affect the treatment effect. ":"2、治疗后要避免出入高热环境，例如三温暖、桑拿等，以免影响治疗效果;",
    "3. After the treatment, there may be slight redness and small tingling, which are normal and mild temporary reactions, no need to worry. ":"3、治疗后可能会出现轻微的泛红、小刺痛等，这都属于正常、温和的暂时性反应，无需担心;",
    "4. according to medical advice twice a day ACME-TEA activation protein, diet to avoid spicy mouth, tobacco and alcohol can not account for, eat more fruits and vegetables, eat lighter food.":"4、按医嘱每天两次ACME-TEA激活蛋白，饮食上要忌口辛辣，烟酒也不能沾，多吃瓜果蔬菜，吃清淡点的食物。",
    "The 7d Poly Latti procedure takes about 40 minutes to complete and you will notice a tightening of the skin after the procedure, which will disappear about 2-3 days after the procedure.":"7D聚拉提手术40分钟左右结束，术后可明显感受到皮肤变紧致，这种紧绷感会在术后2-3天左右的时候消失。",
    "NASA MILK LIGHT":"飞顿铂金牛奶光",
    "THE SKIN LIKE MILK":"让皮肤像牛奶一样",
    "Selected 900-1800mm near infrared spectrum, directly to the dermis, for cellular empowerment.":"精选900-1800mm近红外光谱，直达真皮层，为细胞赋能。",
    "Heraeus top infrared light source, precise and constant energy, instantly visible through white.":"Heraeus顶级红外光源，精准恒定能量，透白即刻可见。",
    "Even energy coverage, in-motion glide technology like hands, touching the whole body.":"能量均匀覆盖，in-motion滑动技术如双手般丝滑，触达全身肌肤。",
    "Usually a single NIR Milk Light treatment takes only about 20 minutes and the results will be seen in one treatment. However, anti-aging programs for skin aging are not permanent. Combating ongoing aging requires continuous treatment to repair the skin and requires a regular course of therapeutic treatments.":"通常单次牛奶光治疗只需20分钟左右，一次治疗就会有效果。但是皮肤老化的抗衰项目不具备永久性，对抗持续的衰老需要持续的治疗来修复肌肤，需要定期接受疗程性治疗。",
    "Milk Light penetrates deep into the skin through infrared light waves, stimulating subcutaneous collagen growth, promoting fibroblast reorganization, smoothing wrinkles and shrinking pores, and restoring the skin's original elasticity. It can effectively solve skin problems such as redness and skin dullness.":"牛奶光通过红外光波穿透至皮肤深层，刺激皮下胶原蛋白增生，促进纤维细胞重组，抚平皱纹收缩毛孔，使皮肤恢复原有弹性。可以有效解决红血丝、皮肤暗沉等皮肤问题。",
    "-Wash your face with room temperature water on the day after the operation;":"· 操作后当天用常温水洗脸；   ",
    "-Pay attention to sun protection after the operation;":"· 操作后注意防晒；",
    "-Pay attention to more hydration and moisturizing after the operation;":"· 操作后注意多补水保湿；",
    "-No makeup is recommended on the day of operation.":"· 当天不建议化妆",
    "-Patients with serious diseases do not operate (three high diabetes heart disease);":"· 严重疾病患者不操作(三高糖尿病心脏疾病）  ",
    "-Skin allergy is not allowed to operate;":"· 皮肤正在过敏不可操作 ",
    "-The skin has wounds or sunburn can not operate;":"· 皮肤有伤口或晒伤不可操作",
    "-Customers who are allergic to light cannot operate;":"· 对光过敏的客户不可操作 ",
    "-Pregnant women are not allowed to operate.":"· 孕妇不可操作",
    "Milk Light is a kind of photorejuvenation, which uses red light wavelength to act directly on the dermis layer of the skin, stimulating the cells in the skin and making the skin smooth and delicate with new growth. The frequency of the program is around 20-30 days, which needs to be decided according to individual skin repair ability.":"牛奶光属于光子嫩肤的一种，利用红光波长可以直接作用于皮肤的真皮层，刺激皮肤内细胞，新生使皮肤变得光滑细腻。项目频率在20-30日左右，需要根据个人皮肤修复能力决定。",
    "HYDRAFACIAL":"海菲秀",
    "LIGHTNING REJUVENATION":"闪电焕肤",
    "Deep hydration:":" ",
    "deep introduction,full level hydration and moisturizing.":"深层补水：深层导入，全层面补水保湿。",
    "Purification:":" ",
    "Resurfacing:":" ",
    "Moisturizing:":" ",
    "Blue whirlpool probe, Activ4 essence helps to replace keratin and revitalize the whole skin.":"净颜：兰色漩涡探头，Activ4精华助力更替角质，全效活肤。",
    "Green negative pressure probe, Beta HD extracts work precisely on T-zone, deep pore cleansing.":"焕肤：绿色负压探头，Beta HD精华精准作用T区，深层清洁毛孔。",
    "Transparent introduction probe, infusing Antiox+ essence into skin, strengthening keratin and protecting skin barrier.":"润颜：透明导入探头，为肌肤注入Antiox+精华，强韧角质保护肌肤屏障。",
    "Three steps":"三大步骤",
    "Hydrafacial deep cleanses the skin by fully combining ultra-microscopic small bubbles and nutrient solution, acting on the skin surface to deep cleanse the face to remove blackheads and old fee keratin without pain and provide nutrients to the skin.":"海菲秀可以对皮肤进行深层清洁，通过超微的小气泡和营养液充分结合，作用在皮肤表面，在没有疼痛感的情况下深层清洁面部去除黑头和老费角质，为皮肤提供营养。",
    "Many doctors in clinical medicine recommend that patients with sensitive skin try Hydrafacial treatments to help soothe and repair the skin barrier.":"海菲秀治疗适用于任何肌肤类型，敏感肌也可以放心进行海菲秀护理疗程，在临床医学中，许多医生建议敏感肌患者尝试海菲秀治疗，有助于舒缓修复肌肤屏障。",
    "The treatment is non-invasive and comfortable. In addition, you can apply makeup directly after the treatment and it will look better.":"海菲秀治疗为非侵入式皮肤护理，全程舒适。另外，海菲秀治疗结束后可以直接上妆，且妆容更加服帖。",
    "The first treatment with Hydrafacial will give you a noticeable refinement of the skin and an even, brighter complexion that will last for many days. With 1-2 treatments per month, you can continue to maintain healthy skin.":"第一次海菲秀治疗可以明显感受到皮肤细腻、肤色均匀明亮，这种效果可以维持多日。每月接受1-2次护理治疗，可以持续保持肌肤健康状态。",
    "Hydrafacial is able to absorb deep-seated skin impurities and also has a revitalizing and rejuvenating effect. Common daily problems such as dull skin, pseudo-wrinkles and rough skin can be completely improved with Hydrafacial.":"海菲秀能够吸净皮肤深层次的污渍，还具备活肤、焕颜的功效。日常常见的皮肤暗黄、假性皱纹、皮肤粗糙等问题，完全可以通过海菲秀进行改善治疗。",
    "VISIA is a professional skin image analysis system that uses advanced multi-spectral imaging technology and patented RBX technology, but since the state of the skin is constantly changing, the test results are only for reference, and specific treatment recommendations are still doctor-based.":"VISIA是专业的皮肤图像分析系统，运用先进的多光谱成像技术和专利RBX技术，但由于皮肤的状态是在不断变化的，因此检测结果只是作为参考，具体治疗建议还是以医生为主。",
      "The higher the score, the better. However, since it is a machine count, the value may fluctuate from one photo to another on the same day. The value is only for reference, and the doctor needs to see the photo in person to judge.":"分值代表超越了百分之多少的同龄人，分值越高越好。但由于是机器计数，同天不同时间的照片数值可能会有浮动，数值只是作为参考，需要医生面诊看照片来判断。",
    "VISIA 7":"皮肤检测仪",
    "SKIN IMAGING SPECIALIST":"皮肤影像专家",
    "See spots, wrinkles, texture, pores, UV blemishes, brown spots, red areas and violet matter all at once for a precise understanding of skin condition.":"一次看到斑点、皱纹、纹理、毛孔、紫外线色斑、棕色斑、红色区和紫质，精准了解肌肤状态。",
    'Skin "8 indicators"':"肌肤「8大指标」",
    "Let you visualize every subtle change, digitally assess skin condition and age, and guide treatment direction.":"让你直观看到每一次的细微改变，数字评估肌肤状态及年龄，引导治疗方向",
    'Integrated "Exclusive Cloud Library"':'集成「专属云库」',
    "JUVEDERM":"乔雅登",
    "12 MONTHS LONG-LASTING BEAUTY":"12个月长效塑颜",
    "LOVE YOUR SELF":"在生活中",
    " ENJOY YOUR LIFE":"更爱自己",
    "READ MORE":"了解更多信息",
    "AWAKEN":"满足",
    "SKIN SENSES":"肌肤真实诉求",
    "EXPERIENCE":"安享",
    "MEDICAL-GRADE":"医疗级专业",
    "CARE":"护理",
    "REVIATLIZE":"精准",
    "SMOOTHING SKIN":"高效焕活肌肤", 
    "AESTHETIC FORMULA":"美学方案",
    "VE AESTHETIC FORMULA":"VE 美学方案",
    "AESTHETIC INSIGHTS":"美学洞察",
    "6 key skin health indicators screening":"检测6大肌肤健康指数",
    "Guide your skincare treatment":"给您治疗的信心与方向",
    "PERSONALIZED NEEDS":"找到所需",
    "One-on-one consultation":"一对一客席顾问",
    "Personalise your beauty needs":"精准了解您的变美诉求",
    "Full range of medical aesthetic programmes":"全品类医学美容项目",
    "Address both deep-seated and surface-level issues":"由表至里的多维解决方案",
    "ENVIRONMENT":"生美平衡",
    "Calm serenity in treatment rooms":"治愈静谧的沙龙诊疗室",
    "Enjoy tranquillity and relaxation while resting":"安心休憩中享受美的原生之力",
    "ENVIRONMENT":"人与空间",
    "NATURAL BEAUTY":"生美平衡",
    "Efficient & natural regimen":"用更加高效自然的方式",
    "Balance both beauty and life":"让生活与美学平衡共生",
    "PRODUCT PARTNERS":"产品联盟",
    "CONTACT US":"联系我们",
    "AESTHETIC PIONEER":"理念焕新",
    "VE Aesthetic is a pioneer of light medical beauty,":"VE医疗美容，作为「轻」医美行业缔造者,",
    "an emerging trend for non-invasive aesthetics with immediate and visible results.":"将为每一位顾客带来有温度、有高度的非手术年轻化体验。",
    "With our brand concept “LOVE & ENJOY”, we are committed to inspire our clients to develop their most beautiful selves. ":"我们以“LOVE&ENJOY”为品牌理念，致力带给您更有质感的生活、自信与勇气。",
    "4 STRATEGIC THRUSTS":`四大步调`,
    "To revolutionize the aesthetics industry":"打造更富活力的医美业态",
    "ADVANCED MEDICAL CONCEPT":"人本质上的医学理念",
    "Synergize current aesthetic techniques with":"将传统医美与先进科技进行相结合",
    "high-tech innovation to enhance safety and efficiency":"让美丽更安全、更高效。",
    "DUAL BEAUTY SYMBIOSIS":`"双美"共生的全新理念`,
    "Non-invasive medical aesthetic to exude both inner and outer beauty":"体验无创医疗美容，让医美贴近生活。",
    "FOREFRONT OF MEDICAL BEAUTY":"创造医美更优解式",
    "Selection of globally renowned skincare brands":"严选世界高端护肤品牌、前沿光电设备",
    "and devices to address your skin needs":" 一站式解决您的肌肤困扰。",
    "PERSONALIZAED INTELLIGENT SERVICE":"私人定制与智慧服务",
    "Customized anti-aging strategy executed with every visit":"从第一次到每一次，打造定制化抗衰策略。",
    "Next Generation Aesthetic Technology":`医美界的"黑科技"`,
    "Facelift, Skin Tightening, Body Contouring":"提升皮肤，改变年龄",
    "ALMA REJUVE":"飞顿铂金牛奶光",
    "Milky White Skin":"让皮肤像牛奶一样",
    "Immediate Skin Resurfacing":"闪电换肤",
    "Skin Complexion Analysis":"皮肤影像专家",
    "Copyright © VE AESTHETIC CLINIC":"版权所有 © 2023 永恒的青春美容诊所",
    "Mesotherapy the first step in medical beauty":"水光一医美第一步",
    "MEDICAL-GRADE CARE":"医疗级专业护理",
    "ONE-STOP SOLUTION":"多维管理",
    'VE Medical Aesthetics, as the creator of the"light" medical aesthetic industry, will bring you a warm and highly non-surgical rejuvenation experience':"VE医疗美容，作为「轻」医美行业缔造者，将为每一位顾客带来有温度、有高度的非手术年轻化体验。",
    'With "LOVE&ENJOY" as our brand concept, we are committed to bring you a more quality life, confidence and courage.':"我们以“LOVE&ENJOY”为品牌理念，致力带给您更有质感的生活、自信与勇气。",
    "THE MEDICAL CONCEPT OF HUMAN NATURE":"人本质上的医学理念",
    "Combining traditional medical beauty with advanced technology to":"将传统医美与先进科技进行相结合",
    "make beauty safer and more efficient.":"让美丽更安全、更高效。",
    'A new concept of "Double Beauty" symbiosis':"“双美”共生的全新理念",
    "Experience non-invasive medical aesthetics, bringingmedical beauty closer to life.":"体验无创医疗美容，让医美贴近生活。",
    "Create A Better Solution For Medical Beauty":"创造医美更优解决式",
    "Recognized by the world's leading high-end brands":"严选世界高端护肤品牌、前沿光电设备",
    "one-stop solution to your skin problems":" 一站式解决您的肌肤困扰。",
    "Personal Customization And Smart Service":"私人定制与智慧服务",
    "Create a Customized Anti-Aging Strategy From the First Time to Every Time.":"从第一次到每一次，打造定制化抗衰策略。",
    "241 River Valley Road":"河谷路241号",
    "Gianurn Building #03-01":"仰思大厦 #03-01",
    "Singapore 238298":"新加坡238298",
    "EXPOLRE":"美好探索",
    "AESTHETIC DEVICES":"高效仪器",
    "Facelift, Skin Tightening,Body Contouring":"提升皮肤，改变年龄",
    '"Black Technology in the medical beauty industry"':"医美界的“黑科技”",
    'Focus on "Honeycomb Lens"':"专注「蜂巢透镜」",
    'Instantaneous"Pigment Blast"':"瞬时「色素爆破」",
    "A picosecond equals one trillionth of a second, Faster speed instantly crushes pigment technology, shorten treatment time.":"一皮秒等于一万亿分之一秒，更快速度瞬间击碎色素科技，缩短治疗时间。",
    'Enjoy "clear, glowing skin"':"恒享「清透美肌」",
    "Unique 755nm wavelength, While reducing heat damage to the skin, Deter melanin activity, Reduce the risk of returning to black.":"独特的755nm波长，减少对皮肤热伤害的同时，震慑黑色素活跃，降低返黑风险。",
    'Promote "Plantar neogenesis"':"促进「肌底新生」",
    "Initiate cell release of protein, Let the skin produce new collagen and elastin from within, Gently smoothes the skin.":"启动细胞释放蛋白质，让皮肤自内产生新的胶原蛋白和弹性蛋白，轻柔抚平肌肤。",
    "Honeycomb picosecond uses a laser and a honeycomb lens together to aggregate most of the laser energy and shatter melanin in different skin layers using targeted treatment. Honeycomb picosecond can promote the regeneration of collagen and collagen fibers, speed up the body's metabolism, and solve the deep skin melanin problem from the root, to achieve the purpose of whitening and rejuvenation.":"蜂巢皮秒将激光和蜂巢透镜搭配使用，把大部分的镭射能量进行聚集，利用靶向治疗的方式震碎不同皮肤层的黑色素。蜂巢皮秒可以促进胶原蛋白和胶原纤维的再生，加快机体的新陈代谢，从根源上解决深层皮肤黑色素问题，实现美白和嫩肤的目的。",
    "All laser treatments are not guaranteed to be completely free of re-blackening, but the risk of re-blackening is much lower than that of traditional lasers because of the small damage to the skin and the return of redness and swelling to the camp. However, after surgery, you need to strengthen sun protection measures and do a good job of hydration. If the post-operative care is not proper, there is also a certain degree of risk of re-tanning.":"所有激光治疗都不能保证完全不反黑，蜂巢皮秒对于皮肤伤害小，红肿返营地，返黑风险较传统激光降低很多。但是术后需要加强防晒措施，做好补水工作。如果术后护理不当，也存在一定程度的返黑风险。",
    "No. Hive picosecond can penetrate into the deeper layers of the skin, and only the melanin particles are affected, so there is no damage to the surface skin.":"不会。蜂巢皮秒能够穿透表层皮肤进入深层皮肤，只对黑色素颗粒产生作用，表层皮肤不会有损伤，所以术后肌肤上不会有疤痕。",
    "Honeycomb picosecond can fade scars, for deep scar depressions it needs to be combined with fractional laser in conjunction with the treatment.":"蜂巢皮秒可以淡化疤痕，对于深的疤痕凹陷需要结合点阵激光配合治疗。",
    "There are many factors that affect the results of Honeycomb picosecond surgery, mainly the mode, number of times, post-operative care and so on. Generally, the results can be seen within 7 days after Honeycomb picosecond surgery because the skin needs a repair cycle.":"蜂巢皮秒手术效果的影响因素非常多，主要有模式、次数、术后护理等等。一般情况下，蜂巢皮秒术后7天内可以看到效果，因为皮肤需要修复周期。",
    "7D POLY LATTI":"7D 聚拉提",
    "The skin like milk":"让皮肤像牛奶一样",
    "Lift skin, change age":"提升皮肤，改变年龄",
    "Lightning rejuvenation":"闪电焕肤",
    "Skin imaging specialist":"皮肤影像专家",
    "ULTRAFORMER MPT":"7D 聚拉提",
    "ULTRA FORMAT MPT BOOST THE SHARP FACE!":"提升锐利的面孔!",
    "The 7d Poly Latti can show the tightening and lifting effect quickly after the treatment, but the effect is not permanent, generally can maintain 2~3 years, the maintenance time is related to the doctor's operation level, the patient's skin condition and post-operative care. The interval between 7d Poly Lattis should not be less than two years.":"7D聚拉提可在治疗后快速显现紧缩提拉效果，但效果不是永久的，一般可维持2~3年，维持时间和医生操作水平、患者的皮肤情况及术后护理有关。做7D聚拉提的间隔时间不能低于两年。",
    "The 7d Poly Latti is performed by focusing ultrasonic energy on the dermis and SMAS fascia layer of the face, heating the tissue to 65°C~75°C. Therefore, it is better to say that it is painful rather than hot; most people do not feel the pain of it, and if the pain of the previous machine lift is ten points, the 7d Poly Latti is less than five points.":"7D聚拉提是透过聚焦超声波能量的作用，在脸部真皮层及SMAS筋膜层，将组织加热到65°C~75°C，所以说，与其说热不如说是痛感；大部分人感觉不到它的痛感，若说以往的机器提拉痛感有十分，那7D聚拉提便为五分以下，不能说完全无感觉，但至少是舒适的选择。",
    "2. After the treatment, you should avoid going in and out of high heat environment, such as sauna, sauna, etc., so as not to affect the treatment effect.":"2、治疗后要避免出入高热环境，例如三温暖、桑拿等，以免影响治疗效果;",
    "3. After the treatment, there may be slight redness and small tingling, which are normal and mild temporary reactions, no need to worry.":"3、治疗后可能会出现轻微的泛红、小刺痛等，这都属于正常、温和的暂时性反应，无需担心;",
    "4. according to medical advice twice a day ACME-TEA activation protein, diet to avoid spicy mouth, tobacco and alcohol can not account for, eat more fruits and vegetables, eat lighter food.":"4、按医嘱每天两次ACME-TEA激活蛋白，饮食上要忌口辛辣，烟酒也不能沾，多吃瓜果蔬菜，吃清淡点的食物。",
    '"Black Technology" in the medical beauty industry':"医美界的“黑科技”",
    "REJUVE IS A HIGHLY EFFECTIVE SKIN REJUVENATION SOLUTION":"高效的嫩肤解决方案",
    "Deep cleansing:":"深层清洁:",
    "cleanse epidermis of sebum, dirt and excess keratin。":"清洁表皮皮脂、脏污及多余角质。",
    "Regulate keratin:":"调节角质：",
    "Deep hydration:":"深层补水",
    "Three main functions。":"三大步骤",
    "Purification:":"净颜：",
    "Resurfacing:":"焕肤：",
    "Moisturizing:":"润颜：",
    "Blue whirlpool probe, Activ4 essence helps to replace keratin and revitalize the whole skin。":" 兰色漩涡探头，Activ4精华助力更替角质，全效活肤。",
    "Green negative pressure probe, Beta HD extracts work precisely on T-zone, deep pore cleansing。":"绿色负压探头，Beta HD精华精准作用T区，深层清洁毛孔。",
    "Transparent introduction probe, infusing Antiox+ essence into skin, strengthening keratin and protecting skin barrier。":"透明导入探头，为肌肤注入Antiox+精华，强韧角质保护肌肤屏障。",
    "12-month long-lasting face sculpting":"12个月长效塑颜",
    "star's Caution":"明星的小心机",
    "Anti-aging Artifact":"抗龄神器",
    '"Mesotherapy The First Step In Medical Beauty"':"水光—医美第一步",
    "star's caution。":"水光—医美第一步",
    "Shuiguang the first step in medical beauty":"水光—医美第一步",
    'Comprehensive "Precision Wrinkle Reduction"':"全面「精准除皱」",
    "The main ingredient of BOTOX is highly purified botulinum toxin type a, a nerve conduction blocking agent used to treat overactive muscles.":"保妥适（BOTOX）的主成分为高度纯化的肉毒杆菌素a型，是一种神经传导的阻断剂，用以治疗过度活跃的肌肉。",
    "BOTOX is a natural, purified protein that relaxes the muscles that cause wrinkles, resulting in smoother, younger, and more youthful skin and the disappearance of dynamic wrinkles.":"保妥适是一种天然、纯化的蛋白质，可让造成皱纹的肌肉放松，肌肤变得平滑、年轻、而且更富有青春活力，使动态性的皱纹消失。",
    'Integration of "water locking and hydration"':"融合「锁水补水」",
    "Girl's another Face":"妳的另一张脸",
    "SHE'S MANAGES":"她管理",
    "Give yourself special":"给自己更好的",
    "Maintenance starting from purifying pores":"养护，从净化毛孔开始",
    "Customized for your skin":"为你量肤定制",
    "However, if the skin dehydration is more serious or fine lines appear, you need to inject it 2~3 times before you can see the obvious effect. Since the effect of hydrafacial injection usually lasts for about half a year or a year, continuous injection is needed to continue the improvement.":"丽珠兰水光针打一次但具有一定的改善效果，但是对于皮肤缺水比较严重或者是出现细纹时，需要注射2~3次之后可以看到明显的效果。因为水光针效果通常维持半年或者是一年左右，所以需要持续注射来继续改善。",
    "12 months long-lasting beauty":"12个月长效塑颜",
    "Understanding skin needs while choosing targeted skincare products to improve skin condition.":"了解肌肤诉求的同时选择针对性护肤品，从而改善皮肤状态。",
    "MAINRENANCE STARTING FROM PURIFYING PORES":"养护，从净化毛孔开始",
    'Soft "Pore Unblocking"':"柔性「疏通毛孔」",
    "The equipment is FDA approved, thus ensuring":"我们的设备，设备通过FDA认证，从而确保您的选择安全有效。",
    "From the front desk, consultants, doctors, nurses to post-operative services, we create the ultimate experience process from smile greeting, professional consultation & treatment & service, personalized service & personal responsibility, tracking service, private private butler service, transparent price system, etc.":"从前台、咨询师、医生、护理师到术后服务等，为用户打造从微笑迎接、专业咨询&治疗&服务、专人专服&专人负责、跟踪式服务、私人私密管家服务、透明价格体系等极致体验流程。",
    "EXPLORE MULTIPLE":"探索",
    "BEAUTIES":"多重美丽",
    "APPLY ONLINE":"在线申请",
    "We cordially invite you to submit your CV only through our online portal":"我们真诚地邀请您仅通过我们的在线门户提交您的简历",
    "Describe in your resume what you want to do, what you're good at, why the job is right for you,and how we can support you in achieving your career vision and working together.":"请在简历中描述您想要做什么、您所擅长的、这份工作适合您的原因，以及我们如何能支持您实现职业愿景、携手并进。",
    "Please send your resume to our email: clinic@veaesthetic.com":"请将简历投送至我们的邮箱：clinic@veaesthetic.com",
    "Or communicate with us online: 65179967":"或者与我们进行线上沟通: 65179967",
    "HIRING NOW":"正在热招",
    "Injectors":"注射医师",
    "Attending Physician":"主诊医师",
    "Key account manager":"大客户经理",
    "Expert assistant":"专家助理",
    "Medical and aesthetic consultant":"医美咨询师",
    "Beauty consultant":"美容顾问",
    "Know her better":"更懂她",
    "Professional and friendly women's team can better understand each girl's nursing needs and help girls improve their private troubles.":"专业亲切的女性团队，更懂每个女生的护理需求，帮助女生改善私密困扰。",
    "Take care of her":"爱护她",
    "Make girls' secret garden more compact, more beautiful, healthier, and enjoy one-stop private care.":"让女生的秘密花园更紧致、更美观、更健康，享受一站式私处护理。",
    "Brands come to VE Aesthetic clinic Center to bring the latest ...":"品牌方空降VE美学中心，带来最新抗衰产品...",
    "HE'S COMING":"他来了",
    "VE Distinguished Aesthetics Expert to Sit at VE Aesthetic clinic on May 4, 2023.":"VE特聘美学专家，2023年x月x日坐镇VE美学中心...",
    "NEW PRODUCT EXPRESS":"北美新科技",
    "The latest North American products are here, limited time discount...":"最新北美黑科技来了！限时折扣体验中...",
    "LIMITED TIME EVENTS":"限时活动",
    "5times s$999 ...":"限时优惠 $999 ...",
    "THE LATEST PRODUCTS":"最新一代产品",
    "Copyright © VE AESTHETIC CLINIC":"版权所有 © 2023 永恒的青春美容诊所.",
    "12-month Long-lasting Face Sculpting":"12个月长效塑颜",
    "star's caution":"明星的小心机",
    'Defining "Perfect Skin"':"定义「完美肌肤」",
    "More detailed facial contour sculpting, solving multiple skin problems at once:":"更细致的面部轮廓雕刻，一次解决多种肌肤问题：",
    "-Even out skin tone":"均匀肤色告别暗沉",
    'Reconstructing the "Facial Architecture"':"重建「面部架构」",
    "The latest Ultra Former MPT is a fivefold new experience:":"最新Ultra Former MPT五重全新体验：",
    "Anyone concerned about sagging skin,fine lines,wrinkles, hyperpigmentation and problematic body areas are an ideal candidates.":"任何对皮肤松弛、细纹、皱纹、色素沉着沉淀和身体局部问题重视的人都适合接受 Ultraformer MPT治疗。",
    "You can see the result immediately right after the treatment. As the collagen regeneration processcontinues your skin continues to improve for up to 6 month after the treatment.":"您可以在治疗后立即见到效果。随着胶原蛋白持续再生，您的皮肤会在治疗后6个月内持续获得改善。",
    "With good aftercare the benefits of the treatment can last for about 1 year.":"如果护理妥当，治疗效果可以持续一年左右。",
    "Some patients may experience slight redness and swell on the treated areas.":"有些客户会在治疗部位出现轻微的红肿。",
    'World-class "star care"':'世界级「明星养护」',
    "From Alma, the manufacturer of the world's most widely used laser aesthetic equipment":"来自世界上应用范围最广的激光美容设备厂商——Alma",
    "The black technology that can efficiently repair the basement membrane band is a skin care program loved by domestic and international celebrities.":" 可以高效修护基底膜带的黑科技，是一款深受国内外明星喜爱的肌肤养护项目。",
    "Protecting the face of a young girl":"守护「少女容颜」",
    'Four "core technologies"':"四大「核心技术」",
    "Milk Light is a high-tech device developed, designed and manufactured by Israel's top medical device manufacturer, Israel Fitton, which makes the treatment process very safe, does not damage the skin, and is very comfortable.":"牛奶光是以色列最顶级的医疗设备厂商色列飞顿公司所研发、设计、生产的高科技设备，治疗过程非常安全、不会损坏皮肤，治疗过程也非常舒适。",
    "By rebuilding the skin barrier with 1300mm near infrared spectrum and 100nm precise light waves, Milk Light enables cell repair and regeneration in the organism, accelerates melanin metabolism, and easily realizes the girlish feeling of fine, tender, bright, and white.":"牛奶光通过用 1300mm 近红外光谱重建肌肤屏障，以及100nm 精准光波，使机体内细胞修复和再生，加速黑色素代谢，轻松实现细、嫩、亮、白的少女感。",
    "Yes, Milk Light solves the problems of excess skin oil and skin laxity through the principle of preferential absorption of water molecules at the NIR 1300nm wave peak, which can make the epidermal cells full and healthy and solve the problem of enlarged pores.":"可以，牛奶光通过NIR1300nm 波峰的水分子优先吸收原理，解决皮肤油脂过剩、皮肤松弛等问题，能让表皮细胞饱满健康，解决毛孔粗大问题。",
    "In contrast to traditional photon's broad spectrum, low absorption rate, and little improvement in skin texture and skin luster; Milk Light adopts the world's only narrow-spectrum light technology, precise light waves on the target tissues have low-energy, high-precision, and more efficient therapeutic effects.":"对比传统光子的宽光谱，低吸收率，对皮肤的质地及皮肤光泽度改善甚微；牛奶光采用全球唯一的窄谱光技术，精准光波对靶组织有低能量、高精度、更高效治疗作用。",
    "Promotes the renewal of connective tissues and protects girlish skin":"促进结缔组织新生，守护少女感肌肤",
  },
};