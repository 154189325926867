<template>
  <div class="pcheader">
    <div
      class="text_1a flex-row w1400 justify-between"
      style="position: relative"
    >
      <div class="text_2a">
        <img
          src="../assets/PCimg/pcheader.png"
          alt=""
          class="cur"
          @click="navPath('/')"
        />
      </div>

      <div class="text_16a flex-row justify-between" v-show="$i18n.locale != 'zn'">
        <div
          class="text_17a cur"
          v-for="(item, index) in headlist"
          @mouseover="move(index)"
          @click="navs(item.hf)"
          :key="index"
          :class="$i18n.locale == 'zn' ? 'l80cn' : ''"
        >
          {{ item.text }}
        </div>
        <!-- <div class="text_17a cur" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("READ MORE") }}</div> -->
      </div>
      <div class="text_16a flex-row justify-between" v-show="$i18n.locale == 'zn'">
        <div
          class="text_17a cur"
          v-for="(item, index) in headlistCn"
          @mouseover="move(index)"
          @click="navs(item.hf)"
          :key="index"
          :class="$i18n.locale == 'zn' ? 'l80cn' : ''"
        >
          {{ item.text }}
        </div>
      </div>
      <div v-show="$i18n.locale != 'zn'">
        <div class="text_3a" @click="cn" v-if="iscn">EN/CN</div>
      </div>
      <div v-show="$i18n.locale == 'zn'">
        <div class="text_3a" @click="en" v-if="iscn">CN/EN</div>
      </div>
        

      <div v-show="$i18n.locale != 'zn'">
        <div class="text_4a" v-show="isshow1" @mouseleave="mou">
        <div v-show="$i18n.locale != 'zn'">
          <div v-for="(item, index) in headlist1" @click="navs(item.hf)"  :key="index">
          <span class="text_5a"> {{ item.text1 }} </span>
        </div>
        </div>



        <div v-show="$i18n.locale == 'zn'">
          <div v-for="(item, index) in headlist2" @click="navs(item.hf)"  :key="index">
          <span class="text_5a"> {{ item.text1 }} </span>
        </div>
      </div>
    </div>
      </div>

      <div v-show="$i18n.locale == 'zn'">
        <div class="text_4aa" v-show="isshow1" @mouseleave="mou">
        <div v-show="$i18n.locale != 'zn'">
          <div v-for="(item, index) in headlist1" @click="navs(item.hf)"  :key="index">
          <span class="text_5a"> {{ item.text1 }} </span>
        </div>
        </div>



        <div v-show="$i18n.locale == 'zn'">
          <div v-for="(item, index) in headlist2" @click="navs(item.hf)"  :key="index">
          <span class="text_5a"> {{ item.text1 }} </span>
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      x: 0,
      headlist: [
        { text: "VE BRAND", hf: "/brand" },
        { text: "SELECT", hf: "/select" },
        { text: "SPECIALTY", hf: "/specialty" },
        { text: "EXPORE", hf: "/explore" },
        { text: "VE CLUB", hf: "/club" },
        { text: "JOIN US", hf: "/joinus" },
      ],
      headlistCn:[
      { text: "VE 品牌", hf: "/brand" },
        { text: "VE 甄选", hf: "/select" },
        { text: "专业领域", hf: "/specialty" },
        { text: "美好探索", hf: "/explore" },
        { text: "VE 俱乐部", hf: "/club" },
        { text: "加入我们", hf: "/joinus" },
      ],
      headlist1: [
        { text1: "POWER INSTRUMENT", hf: "/aestheticDevices" },
        { text1: "MEDICAL INJECTIONS", hf: "/medicalInjections" },
        { text1: "V-CURVE MAINTAIN", hf: "/v-curveMaintain" },
        { text1: "PRECISE SKIN CARE", hf: "/preciseSkinCare" },
      ],
      headlist2: [
        { text1: "高效仪器", hf: "/aestheticDevices" },
        { text1: "改善针剂", hf: "/medicalInjections" },
        { text1: "V曲线养护", hf: "/v-curveMaintain" },
        { text1: "精准护肤", hf: "/preciseSkinCare" },
      ],
      i: 0,
      isshow1: false,
      iscn: true,
    };
  },
 created(){
//   var language = localStorage.getItem(zn);

// if (language === 'zn') {
//   // 设置页面为中文显示
//   this.switchLanguage('zn')
// } else {
//   // 设置页面为英文显示
//   this.switchLanguage('en')
// }
},
  mounted() {
  },
  methods: {
    navPath(e) {
      this.$router.push(e);
    },
    mou(){
      this.isshow1 = false;
    },
    move(index) {
      if (index == 1) {
        this.isshow1 = true;
      } else {
        this.isshow1 = false;
      }
    },
    navs(hf) {
      this.$router.push({
        path: hf,
      });
    },
    // 点击切换中文
    cn(){
      this.iscn = true;
      this.switchLanguage('zn')
    },
     // 点击切换英文
    en() {
        this.iscn = true;
        this.switchLanguage('en')
    },
    switchLanguage(lang) {
    this.$i18n.locale = lang; // 切换当前语言
  },
  },
};
</script>
<style scoped>
.text_1a {
  height: 100px;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
}

.text_2a {
  width: 283.4px;
  height: 39px;
  margin-top: 38px;
  /* margin-right: 40px; */
}

.text_2a img {
  width: 100%;
}

.text_17a {
  font-family: HelT;
  font-size: 22px;
  font-weight: normal;
  line-height: 40px;
  border-bottom: 5px solid transparent;
  color: #000000;
}

.text_17a:hover {
  border-bottom: 5px solid #e6ba91;
  transition: all 0.3s;
}

.text_16a {
  margin-top: 30px;
  width: 880px;
  margin-left: -50px;
}

.text_3a {
  position: absolute;
  right: 0;
  font-family: HelT;
  font-size: 22px;
  font-weight: normal;
  line-height: 40px;
  color: #000000;
  margin-top: 30px;
  cursor: pointer;
}

.text_4a {
  
  background: #e6ba91;
  position: absolute;
  left: 500px;
  top: 95px;
  font-family: HelT;
  font-size: 18px;
  font-weight: 325;
  line-height: 40px;
  color: #ffffff;
  padding: 0 10px;
  z-index: 99;
  transition: all 0.5s;
  /* opacity: 0; */
}
.text_4aa{
  width: 110px;
  height: 173px;
  background: #e6ba91;
  position: absolute;
  left: 475px;
  top: 95px;
  font-family: HelT;
  font-size: 18px;
  font-weight: 325;
  line-height: 40px;
  color: #ffffff;
  padding-left: 11px;
  z-index: 99;
  transition: all 0.5s;
}
.text_5a {
  position: relative;
  cursor: pointer;
}

.text_5a:hover::after {
  width: 100%;
}

.text_5a::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 2px solid #fff;
  position: absolute;
  left: 0;
  bottom: -2px;
  transition-duration: 0.3s;
}

.pcheader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
}
</style>
