<template>
  <div>
    <div class="index_postion" id="myElement">
        <div @click="openWhatsApp"> <img src="../assets/img/index_6.png" alt=""></div>
        <div> <img src="../assets/img/index_7.png" alt=""  id="backToTop"></div>
      </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
     

    };
  },

  mounted() {
// 获取需要展示的元素
var element = document.getElementById("myElement");

window.addEventListener("scroll", function() {
  // 获取窗口滚动距离
  var scrollDistance = window.pageYOffset || document.documentElement.scrollTop;

  // 如果窗口滚动超过200像素，就显示元素
  if (scrollDistance > 100) {
   // 调整元素位置
    element.style.visibility = "visible";  // 显示元素
  } else {
      // 隐藏元素
    element.style.visibility = "hidden";
  }
});
// 获取回到顶部的元素
var backToTop = document.getElementById("backToTop");

// 添加单击事件监听器
backToTop.addEventListener("click", function() {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
});


   

  },
  methods: {
    // onCollision() {
    //   // 发送名为 hideComponentB 的事件
    //   EventBus.$emit('marka');
    // },
    //     jt1(){
    navPath(e) {
      this.$router.push(e);
    },
    openWhatsApp() {
      window.open('https://api.whatsapp.com/send/?phone=6582870301&text=I+saw+your+website+and+I%27m+interested+in+your+services%21&type=phone_number&app_absent=0', '_blank');
    }
  }
}
</script>
<style scoped>


.index_postion {
  position: fixed;
  top: 1200px; /* 初始时元素在屏幕之外 */
  right: 10px;
  left: 10px;
  visibility: hidden; /* 初始时元素不可见 */
  z-index: 77;
  display: flex;
  justify-content: space-between;
  width: 97%;
}

.index_postion img{
  height: 84px;
  width: 84px;
  margin-top: 20px;
 
}

</style>