<template>
  <div style="position: relative; z-index: 9999;">
    <div class="flex-col footer">
      <img src="../assets/img/logof.png" class="logof" />
      <div class="text1" @click="navPath('/brand')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('VE BRAND')}}</div>

      <div class="text5" @click="showSubMenu">
        <div class="text1s1" @click.stop="navPath('/select')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('SELECT')}}</div>
        <img src="../assets/img/jian.png" class="down" v-if="isshow" />
        <img src="../assets/img/jia.png" class="down" v-if="!isshow" />
      </div>
      <ul class="text2" v-if="isshow">
        <li class="text2_1" @click="navPath('/aestheticDevices')" :class="$i18n.locale=='zn'?'l46':''">{{$t('POWER INSTRUMENT')}}</li>
              <li class="text2_1" @click="navPath('/medicalInjections')" :class="$i18n.locale=='zn'?'l46':''">{{$t('MEDICAL INJECTIONS')}}</li>
              <li class="text2_1" @click="navPath('/v-curveMaintain')" :class="$i18n.locale=='zn'?'l46':''">{{$t('V-CURVE MAINTAIN')}}</li>
              <li class="text2_1" @click="navPath('/preciseSkinCare')" :class="$i18n.locale=='zn'?'l46':''">
                {{$t('PRECISE SKIN CARE')}}
              </li>
      </ul>

      <div class="text1" @click="navPath('/specialty')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('SPECIALTY')}}</div>

      <div class="text1" @click="navPath('/explore')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('EXPLORE')}}</div>

      <div class="text1" @click="navPath('/club')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('VE CLUB')}}</div>


      <div class="text1" @click="navPath('/joinus')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('JOIN US')}}</div>



      <div class="flex-row group3">
        <img src="../assets/img/facebook.png" class="icon" @click="face"/>
        <img src="../assets/img/mt-b.png" class="icon"  @click="instagram" />
        <img src="../assets/img/dh.png" class="icon"   @click="openWhatsApp"/>
        <img src="../assets/img/xhs.png" class="icon"  @click="xhs" />
        <img src="../assets/img/wx.png" class="icon"   />
        <img src="../assets/img/dy.png" class="icon"  @click="tiktok" />
      </div>
    </div>
    <div class="flex-col footers">
      <div class="text3" :class="$i18n.locale=='zn'?'fon1':''">{{$t('Copyright © VE AESTHETIC CLINIC')}}</div>
            <!-- <div class="text3" :class="$i18n.locale=='zn'?'fon1':''">{{$t('Copyright © 2023 Vernal Eternity Aesthetic clinic.')}}</div>
      <div class="text3" style="margin-top: -0.3rem;" :class="$i18n.locale=='zn'?'fon1':''">{{$t('Designed by')}} <a href="https://www.hansn.cn/"><span
            class="text4" :class="$i18n.locale=='zn'?'fon1':''">{{$t('HANSN')}}</span></a>{{$t(" BRAND ")}}</div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show1: false,
      show: true,
      isSubMenuVisible: false,
      isshow: false,
      // list:[{t:'VE BRAND',hf:'./brand'},
      //     {t:'SELECT',arr: ["All", "Power Instrument", "Medical Injections","V-curve Maintain","Precise Skin Care"],show1: false,hf:'./aestheticDevices'},
      //     {t:'SPECIALTY'},
      //     {t:'EXPORE'},
      //     {t:'VE CLUB'},
      // ],
    }
  },
  // created() {
  //   // 监听名为 hideComponentB 的事件，并设置回调函数 onHideComponentB
  //   EventBus.$on('marka', this.onHideComponentB);
  // },
  // destroyed() {
  //   // 组件销毁时，取消监听事件
  //   EventBus.$off('marka', this.onHideComponentB);
  // },
  mounted() {
  
  },
  methods: {
    // onHideComponentB() {
    //   // 设置隐藏状态
    //   this.hidden = true;
    // },
    xhs(){
      window.open('https://www.xiaohongshu.com/user/profile/646b71d500000000290137e8?xhsshare=CopyLink&appuid=5c0cad3c000000000600e9a0&apptime=1686108531', '_blank');
    },
    face(){
      window.open('https://www.facebook.com/veaestheticsg', '_blank');
    },
    tiktok() {
      
      window.open('https://www.tiktok.com/@veaestheticsg', '_blank');
    }, 
    instagram() {
      
      window.open('https://www.instagram.com/veaestheticsg', '_blank');
    },
    openWhatsApp() {
      window.open('https://api.whatsapp.com/send/?phone=6582870301&text=I+saw+your+website+and+I%27m+interested+in+your+services%21&type=phone_number&app_absent=0', '_blank');
    },
    showx(i) {
      let bl = !this.list[i].show1;
      this.list.forEach((t) => {
        t.show1 = false;
      });
      this.$set(this.list[i], "show1", bl);
    },
    nav(i) {
      this.$router.push(this.list[i].hf)
      document.documentElement.scrollTop = 0;
    },
    showSubMenu() {
      this.isshow = !this.isshow;
    },
    navPath(e) {
      this.$router.push(e);
    },
  }
}
</script>
<style scoped>
.footer {
  width: 100%;
  background-color: #B77E5F;
  padding: 57px 52px 0px 50px;
}

.logof {
  width: 414px;
  height: 33px;
  margin-bottom: 59px;
}

.text1 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  font-family: HelD;
  color: #fff;
  padding-bottom: 12px;
  margin-bottom: 28px;
  border-bottom: #D8D8D8 0.5px solid;

}

.text1s1 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  font-family: HelD;
  color: #fff;
  padding-bottom: 12px;
  /* margin-bottom: 28px; */

}

/* .x{
  width: 100%;
  height: 0.5px;
  background-color: #D8D8D8;
  margin-top: 12px;
  margin-bottom: 38px;
} */
.text2 {
  font-size: 28px;
  padding: 10px 22px;
  font-family: HelD;
  color: #B77E5F;
  margin-bottom: 31px;
  background: #FFF8EF;
}
.text2_1{
  line-height: 2; /* 设置行高为当前字体大小的1.5倍 */
}

.group2 {
  background-color: #494B4D;
  padding: 20px 0px 0px 22px;
  margin-top: 8px;
  margin-bottom: 35px;
}

.icon {
  width: 57px;
  height: 56px;
  margin-right: 32px;
}

.group3 {
  margin-top: 31px;
  margin-bottom: 43px;
}

.text3 {
  font-size: 24px;
  line-height: 70px;
  font-family: HelD;
  color: #FFF8EF;
}

.text4 {
  font-size: 24px;
  line-height: 70px;
  font-family: HelD;
  color: #FFF8EF;

}

li {
  list-style: none;
  line-height: 40px;
font-weight: 300;

}
.lii1{
    margin-bottom: 10px;
}
.footers {
  width: 100%;
  background-color: #B77E5F;
  border-top: solid 0.5px #FFF8EF;
  padding: 6px 0px 16px 50px;
}

.text5 {
  font-size: 36px;
  line-height: 40px;
  font-family: HelD;
  color: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom: #D8D8D8 0.5px solid;
  margin-bottom: 31px;
}

.down {

  width: 21px;
  height: 40px;
}</style>