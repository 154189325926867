<template>
  <div>
    <div class="pcfooter">
    <div class="w1400" style="position: relative;padding: 0.77rem 0 0.57rem 0;">
      <img src="../assets/PCimg/pcfooter.png" alt="">
      <div class="text_1f flex-row">
        <img src="../assets/PCimg/pcfooter8.png" alt="" class="footerimg">
        <div>clinic@veaesthetic.com</div>
      </div>
      <div class="text_2f flex-row">
        <img src="../assets/PCimg/pcfooter7.png" alt="" class="text_2img">
        <div :class="$i18n.locale == 'zn' ? 'xinxi' : ''">{{ $t("241 River Valley Road") }}<br>
          {{ $t("Gianurn Building #03-01") }}
           <br>
           {{ $t("Singapore 238298") }}
        </div>
      </div>
      <div class="text_3f flex-row">
        <img src="../assets/PCimg/pcfooter1.png" alt="" @click="windowOpen('https://www.facebook.com/veaestheticsg', '_blank')">
        <img src="../assets/PCimg/pcfooter2.png" alt="" @click="windowOpen('https://www.instagram.com/veaestheticsg', '_blank')">
        <img src="../assets/PCimg/pcfooter3.png" alt="" @click="windowOpen('https://api.whatsapp.com/send/?phone=6582870301&text=I+saw+your+website+and+I%27m+interested+in+your+services%21&type=phone_number&app_absent=0', '_blank')">
        <img src="../assets/PCimg/pcfooter4.png" alt="" @click="windowOpen('https://www.xiaohongshu.com/user/profile/646b71d500000000290137e8?xhsshare=CopyLink&appuid=5c0cad3c000000000600e9a0&apptime=1686108531', '_blank')">
        <img src="../assets/PCimg/pcfooter5.png" alt="" >
        <img src="../assets/PCimg/pcfooter6.png" alt="" @click="windowOpen('https://www.tiktok.com/@veaestheticsg', '_blank')">
      </div>


      <div class="text_7f flex-row justify-between">
        <div @click="navPath('/brand')" class="text_7a" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("VE BRAND") }}</div>
        <div @click="navPath('/select')" class="text_7a" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("SELECT") }}</div>
        <div @click="navPath('/specialty')" class="text_7a" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("SPECIALTY") }}</div>
        <div @click="navPath('/explore')" class="text_7a" :class="$i18n.locale == 'zn' ? 'l80cn' : ''"> {{ $t("EXPOLRE") }}</div>
        <div @click="navPath('/club')" class="text_7a" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("VE CLUB") }}</div>
        <div @click="navPath('/joinus')" class="text_7a" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("JOIN US") }}</div>
      </div>
      <div class="text_8f" v-show="$i18n.locale != 'zn'">
        <div @click="navPath('/aestheticDevices')" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("POWER INSTRUMENT") }}</div>
        <div @click="navPath('/medicalInjections')" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("MEDICAL INJECTIONS") }}</div>
        <div @click="navPath('/v-curveMaintain')" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("V-CURVE MAINTAIN") }}</div>
        <div @click="navPath('/preciseSkinCare')" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("PRECISE SKIN CARE") }}</div>
      </div>

      <div class="text_8fa" v-show="$i18n.locale == 'zn'">
        <div @click="navPath('/aestheticDevices')" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("POWER INSTRUMENT") }}</div>
        <div @click="navPath('/medicalInjections')" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("MEDICAL INJECTIONS") }}</div>
        <div @click="navPath('/v-curveMaintain')" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("V-CURVE MAINTAIN") }}</div>
        <div @click="navPath('/preciseSkinCare')" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("PRECISE SKIN CARE") }}</div>
      </div>
    </div>



    <div class="text_4f"></div>
    <div class="flex-row justify-between w1400">
      <div class="text_5f" :class="$i18n.locale == 'zn' ? 'l80cn' : ''">{{ $t("Copyright © VE AESTHETIC CLINIC") }}</div>
      <!-- <div class="text_5f" v-show="$i18n.locale != 'zn'">Designed by <a href="https://www.hansn.cn/" class="text_6f" target="_blank">HANSN</a> BRAND</div> -->
    </div>
  </div>
  </div>
</template>
<script>
export default {

  data() {
   return{

   }

  },

  mounted() {

  },
  methods: {
    navPath(e) {
      this.$router.push(e);
    },
    windowOpen(url){
      window.open(url)
    }
  },
};
</script>
<style scoped>
.pcfooter {
  width: 100%;
  height: 583px;
  background: #B77E5F;
}

.text_1f {
  font-size: 24px;
  font-weight: 325;
  line-height: 37px;
  font-family: HelT;
  margin-top: 47px;
  color: #FFF8EF;
  align-items: center;
}

.text_1f img {
  width: 24px;
  height: 24px;
  margin-right: 24px;
}

.text_2f {
  font-size: 18px;
  font-weight: 325;
  line-height: 30px;
  font-family: HelT;
  margin-top: 17px;
  color: #FFF8EF;
  /* align-items: center; */
  padding-bottom: 72px;
}

.text_2f img {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  margin-top: 3px;
}

.text_3f img {
  margin-right: 26.4px;
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
}

.text_4f {
  width: 100%;
  height: 1px;
  background: #FFF8EF;
}

.text_5f {
  font-family: HelT;
  font-size: 18px;
  line-height: 70px;
  padding: 18px 0 18px 0;
  color: #FFF8EF;
}

.text_6f {
  font-weight: 600;
  color: #fff;
}

.text_7f {
  font-family: HelT;
  font-size: 24px;
  font-weight: normal;
  line-height: 40px;
  color: #FFF8EF;
  width: 931.7px;
  position: absolute;
  right: 0;
  top: 145px;
}
.text_7a{
  border-bottom: 1px solid #FFF8EF;
}
.text_7f>div{
  cursor: pointer;
}

.text_8f {
  width: 240px;
  height: 164px;
  font-family: HelT;
  font-size: 18px;
  font-weight: 325;
  line-height: 40px;
  color: #FFF8EF;
  position: absolute;
  top: 210px;
  left: 650px;
}
.text_8fa{
  width: 240px;
  height: 164px;
  font-family: HelT;
  font-size: 18px;
  font-weight: 325;
  line-height: 40px;
  color: #FFF8EF;
  position: absolute;
  top: 210px;
  left: 625px;
  cursor: pointer;
}
.text_8f>div{
  cursor: pointer;
}
.footerimg{
  width: 24px!important;
height: 18px!important;
}
</style>