import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const ISMOBILE = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};

const routesMobile = [
  {
    path: "/",
    name: "index",
    component: () => import("@/pages/wap/index.vue"),
  },
  {
    path: "/brand",
    name: "brand",
    component: () => import("@/pages/wap/brand.vue"),
    // component:{
    //   () => import("@/pages/wap/brand.vue"),
    //   mobile: () => import("@/pages/wap/brand.vue"),
    //   pc: () => import("@/pages/pc/brand.vue"),
    // },
    // component: () => import(`@/pages/${path}brand.vue`)
  },
  {
    path: "/aestheticDevices",
    name: "aestheticDevices",
    component: () => import("@/pages/wap/product1.vue"),
  },
  {
    path: "/medicalInjections",
    name: "medicalInjections",
    component: () => import("@/pages/wap/product2.vue"),
  },
  {
    path: "/v-curveMaintain",
    name: "v-curveMaintain",
    component: () => import("@/pages/wap/product3.vue"),
  },
  {
    path: "/preciseSkinCare",
    name: "preciseSkinCare",
    component: () => import("@/pages/wap/product4.vue"),
  },
  {
    path: "/picosure",
    name: "picosure",
    component: () => import("@/pages/wap/productny.vue"),
  },
  {
    path: "/ultraformerMpt",
    name: "ultraformerMpt",
    component: () => import("@/pages/wap/productny1.vue"),
  },
  {
    path: "/almaRejuve",
    name: "almaRejuve",
    component: () => import("@/pages/wap/productny2.vue"),
  },
  {
    path: "/hydrafacial",
    name: "hydrafacial",
    component: () => import("@/pages/wap/productny3.vue"),
  },
  {
    path: "/visia7",
    name: "visia7",
    component: () => import("@/pages/wap/productny4.vue"),
  },
  {
    path: "/juvederm",
    name: "juvederm",
    component: () => import("@/pages/wap/productny5.vue"),
  },
  {
    path: "/botox",
    name: "botox",
    component: () => import("@/pages/wap/productny6.vue"),
  },
  {
    path: "/retylane",
    name: "retylane",
    component: () => import("@/pages/wap/productny7.vue"),
  },
  {
    path: "/rejuran",
    name: "rejuran",
    component: () => import("@/pages/wap/productny8.vue"),
  },
  {
    path: "/honeyedJoy",
    name: "honeyedJoy",
    component: () => import("@/pages/wap/productny9.vue"),
  },
  {
    path: "/she'sManages",
    name: "she'sManages",
    component: () => import("@/pages/wap/shemanages.vue"),
  },
  {
    path: "/deepCleaning",
    name: "deepCleaning",
    component: () => import("@/pages/wap/productny10.vue"),
  },
  {
    path: "/skinManngement",
    name: "skinManngement",
    component: () => import("@/pages/wap/productny11.vue"),
  },
  {
    path: "/explore",
    name: "explore",
    component: () => import("@/pages/wap/expore.vue"),
  },
  {
    path: "/select",
    name: "select",
    component: () => import("@/pages/wap/power.vue"),
  },

  ,
  {
    path: "/wapheader",
    name: "wapheader",
    component: () => import("../components/wapheader.vue"),
  },
  {
    path: "/specialty",
    name: "specialty",
    component: () => import("../pages/wap/specialty.vue"),
  },
  {
    path: "/club",
    name: "club",
    component: () => import("../pages/wap/club.vue"),
  },
  {
    path: "/joinus",
    name: "joinus",
    component: () => import("../pages/wap/join.vue"),
  },
];

const routerPC = [
  {
    path: "/",
    name: "index",
    component: () => import("@/pages/index/index.vue"),
  },
  {
    path: "/brand",
    name: "brand",
    component: () => import("@/pages/demo/brand.vue"),
    // component:{
    //   () => import("@/pages/wap/brand.vue"),
    //   mobile: () => import("@/pages/wap/brand.vue"),
    //   pc: () => import("@/pages/pc/brand.vue"),
    // },
    // component: () => import(`@/pages/${path}brand.vue`)
  },
  {
    path: "/wapheader",
    name: "wapheader",
    component: () => import("../components/wapheader.vue"),
  },
  {
    path: "/pcindex",
    name: "pcindex",
    component: () => import("../pages/pc/index.vue"),
  },
  {
    path: "/hydrafacial",
    name: "hydrafacial",
    component: () => import("../pages/pc/hydrafacial.vue"),
  },
  {
    path: "/visia7",
    name: "visia7",
    component: () => import("../pages/pc/visia.vue"),
  },
  {
    path: "/rejuran",
    name: "rejuran",
    component: () => import("../pages/pc/rejuran.vue"),
  },
  {
    path: "/retylane",
    name: "retylane",
    component: () => import("../pages/pc/retylane.vue"),
  },
  {
    path: "/botox",
    name: "botox",
    component: () => import("../pages/pc/botox.vue"),
  },
  {
    path: "/juvederm",
    name: "juvederm",
    component: () => import("../pages/pc/juvederm.vue"),
  },
  {
    path: "/honeyedJoy",
    name: "honeyedJoy",
    component: () => import("../pages/pc/honeyed.vue"),
  },
  {
    path: "/she'sManages",
    name: "she'sManages",
    component: () => import("../pages/pc/shemanages.vue"),
  },
  {
    path: "/skinManngement",
    name: "skinManngement",
    component: () => import("../pages/pc/deepcleaning.vue"),
  },
  {
    path: "/deepCleaning",
    name: "deepCleaning",
    component: () => import("../pages/pc/management.vue"),
  },

  {
    path: "/almaRejuve",
    name: "almaRejuve",
    component: () => import("../pages/pc/nasa.vue"),
  },
  {
    path: "/pcbrand",
    name: "pcbrand",
    component: () => import("../pages/pc/brand.vue"),
  },
  {
    path: "/ultraformerMpt",
    name: "ultraformerMpt",
    component: () => import("../pages/pc/mpt.vue"),
  },
  {
    path: "/pcfooter",
    name: "pcfooter",
    component: () => import("../components/pcfooter.vue"),
  },
  {
    path: "/pcheader",
    name: "pcheader",
    component: () => import("../components/pcheader.vue"),
  },
  {
    path: "/select",
    name: "select",
    component: () => import("../pages/pc/select.vue"),
  },
  {
    path: "/picosure",
    name: "picosure",
    component: () => import("../pages/pc/picosure.vue"),
  },
  {
    path: "/aestheticDevices",
    name: "aestheticDevices",
    component: () => import("../pages/pc/power.vue"),
  },
  {
    path: "/medicalInjections",
    name: "medicalInjections",
    component: () => import("../pages/pc/medical.vue"),
  },
  {
    path: "/v-curveMaintain",
    name: "v-curveMaintain",
    component: () => import("../pages/pc/curve.vue"),
  },
  {
    path: "/preciseSkinCare",
    name: "preciseSkinCare",
    component: () => import("../pages/pc/precise.vue"),
  },
  {
    path: "/explore",
    name: "explore",
    component: () => import("../pages/pc/pcexplore.vue"),
  },
  {
    path: "/club",
    name: "club",
    component: () => import("../pages/pc/major.vue"),
  },
  {
    path: "/joinus",
    name: "joinus",
    component: () => import("../pages/pc/joinus.vue"),
  },
  {
    path: "/specialty",
    name: "specialty",
    component: () => import("../pages/pc/pcspecialty.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: ISMOBILE() ? routesMobile : routerPC,
});

export default router;
