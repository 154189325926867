<template>
  <div id="app">
    <router-view />
    <!-- <router-view></router-view> -->
  </div>
</template>
<script>

export default {
  mounted() {

    // if (this._isMobile()) {
    //   this.nav('/')
    // } else {
    //   this.nav('/pcindex')
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }

  }
}
</script>
<style>
html {
  font-size: 100PX !important;
}

@media screen and (max-width: 1500px) {
  html {
    font-size: 80PX !important;
  }
}

@media screen and (max-width:1200px) {
  html {
    font-size: 70PX !important;
  }

}

@media screen and (max-width: 1100px) {
  html {
    font-size: 60PX !important;
  }
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 13.333333333vw !important;
  }
}

@media screen and (max-width: 850px) {
  html {
    font-size: 13.333333333vw !important;
  }
}

@font-face {
  font-family: Roboto;
  src: url("./font/Roboto-ThinItalic-16.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Flynnmono-Bold;
  src: url("./font/Flynnmono-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Fa;
  src: url("./font/Flynnmono-light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Flynnmono-Normal;
  src: url("./font/Flynnmono-Normal.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Flynnmono-VF;
  src: url("./font/Flynnmono-VF.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Flynnmono;
  src: url("./font/Flynnmono.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Chron;
  src: url("./font/ChronicleDisplay-Semi.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Galano;
  src: url("./font/Galano\ Grotesque.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: GalanoB;
  src: url("./font/Galano\ Grotesque\ Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: GalanoL;
  src: url("./font/Galano\ Grotesque\ Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: HelD;
  src: url('./font/HelveticaNowDisplay.OTF') format('opentype');

}

@font-face {
  font-family: HelN;
  src: url('./font/HelveticaNowText.OTF') format('opentype');

}

@font-face {
  font-family: ChronD;
  src: url('./font/Chronicle\ Display\ Light.otf') format('opentype');

}

@font-face {
  font-family: mingolia;
  src: url('./font/mingolia-display-mingolia-display-regular-400.ttf') format('opentype');

}

@font-face {
  font-family: HelN;
  src: url('./font/HelveticaNowText.OTF') format('opentype');

}

@font-face {
  font-family: HelB;
  src: url('./font/HelveticaNowDisplayXBd.OTF') format('opentype');

}

@font-face {
  font-family: HelT;
  src: url('./font/HelveticaNowDisplayLight.OTF') format('opentype');

}

@font-face {
  font-family: HelTh;
  src: url('./font/HelveticaNowDisplayThin.OTF') format('opentype');

}
@font-face {
  font-family: HeXLt;
  src: url('./font/HelveticaNowDisplayXLt.OTF') format('opentype');

}

* {
  text-underline-offset: 0.1rem;
  margin: 0;
  padding: 0;
}

input {
  outline: none;
}

.over100 {
  height: 100vh;
  over-flow: auto;
}

.sticky {
  position: sticky !important;
  top: .8rem;
  z-index: 90;
  background-color: #fff;
}

.fontblod,
.font-bold {
  font-weight: bold;
}

/************************************************************
  ** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
  ** 否则页面将无法正常显示                                  **
  ************************************************************/


.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 .2rem;
}

.Robotol {
  font-family: Robotol;
}

.swiper {
  width: 100%;
}

html {
  font-size: 13.3333333333vw;
}

body {
  margin: 0;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
}

body * {
  box-sizing: border-box;
  flex-shrink: 0;
}

#app {
  /* width: 100vw; */
  height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.w1400{
  width: 1400px;
  margin: 0 auto;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.relative {
  position: relative;
}

.cur {
  cursor: pointer;
}

.overh {
  overflow: hidden;
}

.head {
  position: relative;
  margin-top: 127px;
}

.head img {
  width: 100%;
}

.head-content {
  position: absolute;
  top: 123px;
  width: 100%;
}

.head-text {
  width: 1400px;
  margin: 0 auto;
}

.head-text1 {
  font-size: 24px;
  margin-bottom: 23px;
}

.head-text2 {
  font-size: 40px;
  font-weight: bold;
  line-height: 56.16px;
  margin-bottom: 23px;
  letter-spacing: 3px;
}

.head-line {
  width: 80px;
  height: 3px;
  background: #ff0000;
}

img {
  border: none;
  vertical-align: middle;
  display: block;
}

.roboto {
  font-family: Roboto;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: #000;
}

.f-c {
  display: flex;
  align-items: center;
}

.f-b-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f-a-c {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.p40{
  padding-left: 40px;
  padding-right: 40px;
}
.l3D {

  color: #3d3d3d !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
}

.l50 {
  line-height: 50px !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
  text-align: justify;
}

.l37 {
  line-height: 37px !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
  text-align: justify;
}
.l86cn {
  line-height: 86px !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
}
.l90 {
  line-height: 90px !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
}
.l70 {
  line-height: 70px !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
}
.l46 {
  line-height: 46px !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
}
.fon1{
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
}
.fon2{
  margin-bottom: 150px !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
  text-align: justify;
}
.fon3{
  line-height: 50px !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
  text-align: justify;
  margin-top: -35px;
}
.text_1_title{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  font-family: mingolia;
  font-size: 72px;
  line-height: 100px;
  color: #B77E5F;
}
.ljie{
  font-size: 18px;
  line-height: 30px;
  font-weight: 325;
  font-family: HelT;
  margin-top: 17px;
  color: #FFF8EF;
  /* align-items: center; */
  padding-bottom: 72px;
}
/* 图片自适应 */
.max_img{
  width: 338px;
  height: 522px;
}
.max_img img{
  width: 100%;
  height: 100%;
}
.max_wh{
  width: 721px;
  height: 999px;
}
.max_wh img{
  max-width: 100%;
  max-height: 100%;
}
.nyop {
  position: relative;
  width: 100%;
}
.nyop_text{
  position: absolute;
  top: 39px;
  left: 50px;
}
.nyop_text1{
  font-size: 36px;
  line-height: 78px;
  font-weight: 500;
  font-family: mingolia;
  color: #B77E5F;
  margin-bottom: 33px;
}
.nyop_text2{
  font-size: 20px;
  line-height: 26px;
  font-weight: 325;
  color: #000;
  font-family: HelT;
}
.nyop img {
  width: 100%;
}
.exp_ve_texts{
  font-size: 24px;
  line-height: 40px;
  color: #B77E5F;
}
.fz150{
  font-size: 24px !important;
  line-height: 50px !important;
  font-weight: 400 !important;
  font-family: "PingFang SC", 'Microsoft Yahei' !important;
  text-align: justify;
}
.fz15032{
  line-height: 50px !important;
  font-size: 32px !important;
}
.fz15026{
  line-height: 50px !important;
  font-size: 26px !important;
}

</style>

