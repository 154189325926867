<template>
  <div class="body" style="z-index: 9999999999 ">
    <div
      class="header flex-row justify-between items-center"
      :class="showd == true ? 'active' : ''"
    >
      <img
        src="../assets/img/logom.png"
        class="logom"
        v-if="showda"
        @click="navPath('/')"
      />

      <img
        src="../assets/img/annu.png"
        alt=""
        v-if="isff"
        @click="open"
        class="iii"
      />
      <img
        src="../assets/img/ca.png"
        alt=""
        v-if="!isff"
        @click="close"
        class="iii"
      />

      <img
        src="../assets/img/en1.png"
        alt=""
        class="en"
        v-if="iscn"
        @click="en"
        v-show="$i18n.locale != 'zn'"
      />
      <img
        src="../assets/img/cn1.png"
        alt=""
        class="en"
        v-if="iscn"
        @click="cn"
        v-show="$i18n.locale == 'zn'"
      />
    </div>

    <collapse-transition>
      <div class="flex-col headers" v-if="show">
        <div style="" class="yybk"></div>
        <div class=" ">
          <div class="text1">
            <div class="text3" @click="navPath('/brand')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('VE BRAND')}}</div>
            <div class="text4" @click="showSubMenu">
              <div class="text6" @click="navPath('/select')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('SELECT')}}</div>
              <img src="../assets/img/up.png" class="down" v-if="isshow" />
              <img src="../assets/img/down.png" class="down" v-if="!isshow" />
            </div>
            <ul class="text5" v-if="isshow">
              <li @click="navPath('/aestheticDevices')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('POWER INSTRUMENT')}}</li>
              <li @click="navPath('/medicalInjections')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('MEDICAL INJECTIONS')}}</li>
              <li @click="navPath('/v-curveMaintain')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('V-CURVE MAINTAIN')}}</li>
              <li @click="navPath('/preciseSkinCare')" class="text8" :class="$i18n.locale=='zn'?'fon1':''">
                {{$t('PRECISE SKIN CARE')}}
              </li>
            </ul>
            
            <div class="text7" @click="navPath('/specialty')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('SPECIALTY')}}</div>
            <div class="text7" @click="navPath('/explore')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('EXPLORE')}}</div>
            <div class="text3" @click="navPath('/club')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('VE CLUB')}}</div>
            <div class="text3" @click="navPath('/joinus')" :class="$i18n.locale=='zn'?'fon1':''">{{$t('JOIN US')}}</div>
          </div>
        </div>
        <div class="iconp">
          <div class="flex-row ic" >
            <img src="../assets/img/he1.png" class="icon" @click="face"/>
            <img src="../assets/img/he2.png" class="icon" @click="instagram"/>
            <img src="../assets/img/he3.png" class="icon" @click="openWhatsApp"/>
            <img src="../assets/img/he4.png" class="icon" @click="xhs"/>
            <img src="../assets/img/he5.png" class="icon" @click=""/>
            <img src="../assets/img/he6.png" class="icon" @click="tiktok"/>
          </div>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
export default {
  props: { showd: Boolean, Boolean },
  data() {
    return {
      active: false,
      index: null,
      show1: false,
      show: false,
      isSubMenuVisible: false,
      isshow: false,
      imageIndex: 0,
      isff: true,
      showda: true,
      iscn: true,

      // images: ['../assets/img/annu.png', '../assets/img/annu.png']
    };
  },

  mounted() {},
  methods: {
    xhs(){
      window.open('https://www.xiaohongshu.com/user/profile/646b71d500000000290137e8?xhsshare=CopyLink&appuid=5c0cad3c000000000600e9a0&apptime=1686108531', '_blank');
    },
    face(){
      window.open('https://www.facebook.com/veaestheticsg', '_blank');
    },
    tiktok() {
      
      window.open('https://www.tiktok.com/@veaestheticsg', '_blank');
    }, 
    instagram() {
      
      window.open('https://www.instagram.com/veaestheticsg', '_blank');
    },
    openWhatsApp() {
      window.open('https://api.whatsapp.com/send/?phone=6582870301&text=I+saw+your+website+and+I%27m+interested+in+your+services%21&type=phone_number&app_absent=0', '_blank');
    },
    open() {
      this.isff = false;
      this.show = true;
    },
    close() {
      this.isff = true;
      this.show = false;
    },

    en() {
      this.iscn = true;
      this.switchLanguage('zn')
    },
    cn() {
      this.iscn = true;
      this.switchLanguage('en')


    },
    switchLanguage(lang) {
    this.$i18n.locale = lang; // 切换当前语言
  },
    changeImage() {
      this.imageIndex = (this.imageIndex + 1) % this.images.length;
    },
    // 跳转路由，并强制执行跳转操作
    navPath(path, force = false) {
      if (!force && this.$route.path === path) {
        // 如果当前路由已经是目标路径，且不强制跳转，则不进行任何操作
        return;
      }
      this.$router.push({ path });
    },
    shows(i) {
      let bl = !this.list[i].show1;
      this.list.forEach((t) => {
        t.show1 = false;
      });
      this.$set(this.list[i], "show1", bl);
    },

    showSubMenu() {
      this.isshow = !this.isshow;
    },
  },
};
</script>
<style scoped>

.yybk{
  height: 1px;
   box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
   background-color: rgba(0, 0, 0, 0.1);
}
.header {
  width: 100%;
  height: 102px;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.active {
  background-color: #f5ece2;
}

.logom {
  width: 345px;
  height: 27px;
  margin-left: 52px;
  /* position: absolute;
  top: 38px;
  left: 50px; */
}
.an {
  width: 44px;
  height: 34px;
  position: absolute;
  top: 36px;
  right: 53px;
}
.logop {
  width: 345px;
  height: 27px;
  /* position: absolute;
  top: 38px;
  left: 50px; */
}
.annu {
  width: 44px;
  height: 34px;
  margin-top: -2px;
}
.text6 {
  font-weight: 400;
  font-family: HelD;
}

.headers {
 
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 100px;
  left: 0;
  z-index: 99;
  /* padding: 38px 53px 0px 50px; */
  overflow-y: hidden;
}

.headers::-webkit-scrollbar {
  width: 0;
}
.logos {
  width: 414px;
  height: 33px;
}
.ca {
  width: 44px;
  height: 35px;
}
.group1 {
  /* margin-bottom: 82px; */
  height: 120px;
}
.group2 {
  margin-top: 35px;
  margin-bottom: 37px;
  position: relative;
  width: 100%;
}
.iconp {
  position: relative;
}
.ic {
  position: absolute;
  top: 116px;
  left: 50px;
  padding-bottom: 1000px;
}
.text1 {
  font-size: 48px;
  line-height: 48px;
  font-family: HelD;
  padding-left: 52px;
  margin-top: 114px;
}

.text2 {
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 20px;
  font-family: HelD;
}
.logopp {
  width: 345px;
  height: 27px;
  margin-left: 52px;
  /* position: absolute;
  top: 38px;
  left: 50px; */
}
.text3 {
  text-decoration: underline;
  margin-bottom: 52px;
  height: 46px;
  font-size: 48px;
  font-family: HelD;
  font-weight: 400;
}
.text7 {
  text-decoration: underline;
  margin-bottom: 52px;
  height: 46px;
  font-size: 48px;
  font-family: HelD;
  font-weight: 400;
  padding-top: 10px;
}
.body {
  position: relative;
}

.text4 {
  width: 647px;
  text-decoration: underline;
  margin-bottom: 37px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  align-items: center;
  font-family: HelD;
}

li {
  list-style: none;
  line-height: 40px;
  /* margin-top: 11px; */
  font-family: HelD;
  margin-bottom: 23px;
  font-size: 30px;
  font-weight: 300;
}
.down {
  width: 34px;
  height: 18px;
  /* margin: auto ; */
}
.text5 {
  text-decoration: none;
  /* width: 338px; */
  /* height: 227px;
  padding-bottom: 40px; */
}
/* .text8{
  line-height: 30px;
} */
.iii {
  width: 43.5px;
  height: 31.5px;
  position: absolute;
  top: 36px;
  right: 50.5px;
}
.icon {
  width: 57px;
  height: 56px;
  margin-right: 32px;
}

.en {
  width: 44px;
  height: 42px;
  position: absolute;
  top: 32px;
  right: 134px;
}
</style>
