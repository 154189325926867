import axios from 'axios';
 
axios.defaults.timeout = 30000;
 
 
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
 
    //可以写if判断，提前拦截错误信息
 
    return response;
 
}, function (err) {
 
    return Promise.reject(err);
});
 
 
export function get(url, params){ 
	return new Promise((resolve, reject) =>{ 
		axios.get(url, { 
			params: params,
			headers:{"token":sessionStorage.getItem('token')}
		}).then(res => {
			resolve(res.data);
		}).catch(err =>{
			reject(err.data) 
		}) 
   });
}
 
 
export function post(url, params){
	return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url:url,
				data:params
			}).then(res => {
				resolve(res.data);
            }).catch(err =>{reject(err.data)})
        });
    }
 