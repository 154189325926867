import Vue from 'vue';
import App from './app.vue';
import router from './router';

import messages from './lang/message.js';
import VueI18n from 'vue-i18n';
import ModalWizard from 'vue-modal-wizard'
import CollapseTransition from './collapse-transition.js';
import "../node_modules/aos/dist/aos.css";
import {get,post} from '../src/api/api'

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.prototype.$get  = get
Vue.prototype.$post = post
Vue.use(VueI18n);
Vue.use(ModalWizard)
Vue.prototype.nav = (url) => {
  //window.location.href=url
  router.push({path: url})
}
Vue.config.productionTip = false;
// Vue.component("Vheader", Vheader)
Vue.mixin({
  components: {
    'collapse-transition': CollapseTransition,
  },
  
  data() {
    return {cpshow: true}
  },
  mounted() {
    setTimeout(() => {
      this.cpshow = false
    }, 4000)

  },
  methods: {
    goAnchor(pos) {
      this.cc=pos
      this.$nextTick(() => {
        $('.group').animate({
          scrollTop: document
            .getElementById(pos)
            .getBoundingClientRect()
            .top - 100
        }, 600);
      })

      

    },
    setcpshows() {
      this
        .$refs
        .cpnav
        .setcpshow()
    }
  }
})

const i18n = new VueI18n({
  locale: 'en', // 设置默认语言
  fallbackLocale: 'en', // 在当前语言无法翻译某些内容时使用的降级语言
  messages, // 将语言资源文件注入到实例中
});

import marka from "./components/marka.vue";
import pcheader from "./components/pcheader.vue";
import pcfooter from "./components/pcfooter.vue";
import wapheader from "./components/wapheader.vue";
import Wapfooter from './components/wapfooter.vue';
import AOS from 'aos';
import "../public/aos.css";
Vue.use(AOS);
import VuePrlx from 'vue-prlx'
Vue.use(VuePrlx);
//import animated from 'animate.css' // npm install animate.css --save安装，在引入
//Vue.use(animated)
// import { WOW } from 'wowjs'
// Vue.prototype.$wow = new WOW({
//   boxClass: 'wow', // default
//   animateClass: 'animated', // default
//   offset: 150, // default
//   mobile: true, // default
//   live: false,

//   // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

//   callback: function (box) {
//     console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
//   }
// });
// Vue.mixin({
//   mounted(){
//     AOS.init({
//       offset: 0,
//       duration: 600,
//       delay: 600,
//       once: true,
//     })
//   }
// })

// Vue.config.productionTip = false;

// router.beforeEach((to, from, next) => {
//   // 判断当前路由是否是首页
//   Vue.prototype.$isHome = to.path === "/";
//   next();
// });


Vue.component("pcheader", pcheader);
Vue.component("marka",marka)
Vue.component("pcfooter", pcfooter);
Vue.component("wapheader", wapheader);
Vue.component("Wapfooter",Wapfooter)
new Vue({
  i18n,
  router,
  render: (h) => h(App)
}).$mount('#app');
router.afterEach((to,from,next) => {
   window.scrollTo(0,0);
});
